import { ViewportCoordinate } from '../types'

export const PINCH_START = 'PINCH_START'
export const PINCH = 'PINCH'
export const PINCH_END = 'PINCH_END'

interface PinchStartAction {
    type: typeof PINCH_START
    payload: ViewportCoordinate
}

interface PinchAction {
    type: typeof PINCH
    payload: {
        x: ViewportCoordinate
        y: ViewportCoordinate
        scaleFactor: number
        pinchPointDistance: ViewportCoordinate
    }
}

interface PinchEndAction {
    type: typeof PINCH_END
    payload: undefined
}

export type PinchActionTypes = PinchStartAction | PinchAction | PinchEndAction

export const pinchStartAction = (
    pinchPointDistance: ViewportCoordinate
): PinchActionTypes => {
    return {
        type: PINCH_START,
        payload: pinchPointDistance,
    }
}

export const pinchAction = (
    x: ViewportCoordinate,
    y: ViewportCoordinate,
    scaleFactor: number,
    pinchPointDistance: ViewportCoordinate
): PinchActionTypes => {
    return {
        type: PINCH,
        payload: { x, y, scaleFactor, pinchPointDistance },
    }
}

export const pinchEndAction = (): PinchActionTypes => {
    return {
        type: PINCH_END,
        payload: undefined,
    }
}

export interface PinchState {
    pinchPointDistance: ViewportCoordinate | null
    pinching: boolean
}

export const initialPinchState: PinchState = {
    pinchPointDistance: null,
    pinching: false,
}

export const pinchReducer = (
    state = initialPinchState,
    action: PinchActionTypes
) => {
    switch (action.type) {
        case PINCH_START:
            return {
                ...state,
                pinching: true,
                pinchPointDistance: action.payload,
            }
        case PINCH:
            return {
                ...state,
                pinchPointDistance: action.payload.pinchPointDistance,
            }
        case PINCH_END:
            return {
                ...state,
                pinching: false,
                pinchPointDistance: null,
            }
        default:
            return state
    }
}
