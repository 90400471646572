import { useState, useEffect, EffectCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import mapValues from 'lodash.mapvalues'
import {
    zoomInTile,
    ZoomStatus,
    unsetZoomed,
    setZoomed,
} from './store/zoomedTile'
import { TILE_SIZE, PAGE_ZOOM_DURATION } from './constants'
import { calculateZoomTransform, linearCoefficients } from './maths'
import { setTransform } from './store/panZoom'
import {
    selectZoomedTileId,
    selectTileFromId,
    selectZoomedTileStatus,
    selectWindowDimensions,
} from './selectors'
import { RootState } from './store'
import { TileId, DeviceScaledSettings } from './models'
import theme from './theme'
import { createSelector } from 'reselect'

// Ref : https://www.digitalocean.com/community/tutorials/creating-a-custom-usefetch-react-hook
export const useGetJson = function <JsonStructure>(url: string) {
    const [response, setResponse] = useState<JsonStructure | null>(null)
    const [error, setError] = useState<Error | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            try {
                const res = await fetch(url)
                const body = await res.json()
                setResponse(body)
                setIsLoading(false)
            } catch (error) {
                setError(error)
            }
        }
        fetchData()
    }, [url])
    return { response, error, isLoading }
}

// Implements the lifecycle / side-effects for tile zooming-in.
// It is being called explicitely when a component wants to zoom-in to a new tile,
// and it then handles the timings and other quirks for the animations.
// It returns an effect function to be called, so that the lifecycle can also be initiated
// on user interaction.
export const useRequestZoomToTile = (
    nextZoomedTileId: TileId | null
): [EffectCallback, Array<number | null | string>] => {
    const dispatch = useDispatch()
    const { width, height } = useSelector(selectWindowDimensions)
    const { zoomToPageScaleBack, zoomToPageScale } = useDeviceScaledSettings()
    const zoomedTileStatus = useSelector(selectZoomedTileStatus)
    const zoomedTileId = useSelector(selectZoomedTileId)
    const zoomedTile = useSelector((state: RootState) => {
        if (zoomedTileId) {
            return selectTileFromId(state, zoomedTileId)
        } else {
            return null
        }
    })

    const dependencies = [
        zoomedTileStatus,
        nextZoomedTileId,
        zoomedTileId,
        width,
        height,
    ]

    const effect = () => {
        // NONE -> ZOOMING_IN
        if (nextZoomedTileId !== null && zoomedTileStatus === ZoomStatus.NONE) {
            dispatch(zoomInTile(nextZoomedTileId!))

            // ZOOMING_IN -> ZOOMED
        } else if (zoomedTileStatus === ZoomStatus.ZOOMING_IN) {
            const {
                scale,
                translate: { x, y },
            } = calculateZoomTransform(
                zoomedTile!.position.x + TILE_SIZE / 2,
                zoomedTile!.position.y + TILE_SIZE / 2,
                zoomToPageScale,
                width,
                height
            )
            dispatch(setTransform(scale, x, y))
            // We start a timeout to let the zoom animation time to finish
            const timeoutId = setTimeout(
                () => dispatch(setZoomed()),
                PAGE_ZOOM_DURATION * 1000 + 50
            )
            return () => {
                clearTimeout(timeoutId)
            }

            // ZOOMING_OUT -> NONE
        } else if (zoomedTileStatus === ZoomStatus.ZOOMING_OUT) {
            const {
                scale,
                translate: { x, y },
            } = calculateZoomTransform(
                zoomedTile!.position.x + TILE_SIZE / 2,
                zoomedTile!.position.y + TILE_SIZE / 2,
                zoomToPageScaleBack,
                width,
                height
            )
            dispatch(setTransform(scale, x, y))
            // We start a timeout to let the unzoom animation time to finish
            const timeoutId = setTimeout(
                () => dispatch(unsetZoomed()),
                PAGE_ZOOM_DURATION * 1000 + 50
            )
            return () => {
                clearTimeout(timeoutId)
            }
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(() => [effect, dependencies], dependencies)
}

export const useDeviceScaledSettings = () => {
    const { width } = useSelector(selectWindowDimensions)
    // If the width is zero we return default values to avoid
    // confusing the memoized reselect function
    if (width === 0) {
        return mapValues(
            theme.deviceScaledSettings,
            ([_, valueBig]) => valueBig
        )
    }
    const coeffs = _computeSettingsInterpolations(1)
    return _computerInterpolatedSettings(width, coeffs)
}

const _computerInterpolatedSettings = createSelector(
    (width: number, _: InterpolationCoefficients) => width,
    (_, coeffs) => coeffs,
    (width, coeffs) => {
        return mapValues(coeffs, ([a, b]) => a * width + b)
    }
)

const _computeSettingsInterpolations = createSelector(
    // Dummy, to make reselect typings happy
    () => 1,
    () => {
        const [widthSmall, widthBig] = theme.deviceScaledSettings.width
        return mapValues(
            theme.deviceScaledSettings,
            ([valueSmall, valueBig]) => {
                return linearCoefficients(
                    {
                        x: widthSmall,
                        y: valueSmall,
                    },
                    {
                        x: widthBig,
                        y: valueBig,
                    }
                )
            }
        )
    }
)

type DeviceScaledSettingsKey = keyof DeviceScaledSettings
type InterpolationCoefficients = {
    [k in DeviceScaledSettingsKey]: [number, number]
}
