import React from 'react'
import ThumbnailImage from './ThumbnailImage'
import { FrontendTile } from '../../models'
import styled from 'styled-components/macro'
import { MediaObjectType } from '../../pre-build/static'
import ThumbnailVideo from './ThumbnailVideo'
import { buildThumbnailVideoId } from '../../utils'
import { HexagonClippedGroup, useTileClick } from './shared'

const Container = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
    background-color: transparent;
`

interface Props {
    tile: FrontendTile
    isFocused: boolean
}

const TileHexagonNormal = ({ tile, isFocused }: Props) => {
    const tileClickHandlers = useTileClick(tile.id)
    const thumbnailComponent =
        tile.thumbnailType === MediaObjectType.VIDEO ? (
            <ThumbnailVideo
                urls={tile.thumbnails}
                videoId={buildThumbnailVideoId(tile.id)}
            />
        ) : (
            <ThumbnailImage urls={tile.thumbnails} />
        )

    return (
        <Container>
            <HexagonClippedGroup {...tileClickHandlers}>
                {thumbnailComponent}
            </HexagonClippedGroup>
        </Container>
    )
}

export default TileHexagonNormal
