import styled from 'styled-components/macro'
import React from 'react'
import { TILE_SIZE } from '../../constants'
import { Point } from '../../models'

interface Props {
    position: Point
    className?: string
}

const Container = styled.div`
    position: absolute;
    /* this is the parent container, whose children will be cut as HEXes, therefore
    we need to let clicks go through to these hexes */
    pointer-events: none;
`

export const HexagonContainer: React.FunctionComponent<React.PropsWithChildren<
    Props
>> = ({ position, className, children }) => {
    // Apply offset to center the tile around 0
    const centeredPosition = {
        x: position.x - TILE_SIZE / 2,
        y: position.y - TILE_SIZE / 2,
    }
    // We inline this style here to not put it in the styled components, which would create many classes.
    const inlineStyle: React.CSSProperties = {
        left: `${centeredPosition.x}px`,
        top: `${centeredPosition.y}px`,
        width: `${TILE_SIZE}px`,
        height: `${TILE_SIZE}px`,
    }
    return (
        <Container style={inlineStyle} className={className}>
            {children}
        </Container>
    )
}

export default React.memo(HexagonContainer)
