import { BoundingBox, Point, Transform } from './models'
import { HexPoint } from './pre-build/static'

export const calculateBoundingBox = (points: Array<Point>): BoundingBox => {
    const xValues = points.map((point) => point.x)
    const yValues = points.map((point) => point.y)
    return {
        topLeft: {
            x: Math.min(...xValues),
            y: Math.min(...yValues),
        },
        bottomRight: {
            x: Math.max(...xValues),
            y: Math.max(...yValues),
        },
    }
}

export const calculateDistance = (a: Point, b: Point): number =>
    Math.pow(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2), 0.5)

export const calculatePointFromHexPoint = (
    hexPoint: HexPoint,
    tileSize: number
): Point => {
    return {
        x: (hexPoint.hex_x * tileSize) / 2,
        y: (hexPoint.hex_y * tileSize * 3) / 4,
    }
}

// Given x min, calculate the leftmost hex visible (even if it is cropped)
export const calculateMinHexX = (x: number, tileSize: number): number => {
    return Math.ceil(x / (tileSize / 2) - 1)
}

// Given x max, calculate the rightmost hex visible (even if it is cropped)
export const calculateMaxHexX = (x: number, tileSize: number): number => {
    return Math.floor(x / (tileSize / 2) + 1)
}

// etc ...
export const calculateMinHexY = (y: number, tileSize: number): number => {
    return Math.ceil((y + (tileSize * 1) / 4) / ((tileSize * 3) / 4) - 1)
}

export const calculateMaxHexY = (y: number, tileSize: number): number => {
    return Math.floor((y - (tileSize * 1) / 4) / ((tileSize * 3) / 4) + 1)
}

export const calculateZoomTransform = (
    x: number,
    y: number,
    scale: number,
    width: number,
    height: number
): Transform => {
    const translateX = -x * scale + width / 2
    const translateY = -y * scale + height / 2

    return {
        scale,
        translate: {
            x: translateX,
            y: translateY,
        },
    }
}

export const linearCoefficients = (p0: Point, p1: Point): [number, number] => {
    // Ref: https://en.wikipedia.org/wiki/Linear_interpolation
    // equation: y = y0 + (x - x0) * (y1 - y0) / (x1 - x0)
    // =>        y = y0 - x0 * (y1 - y0) / (x1 - x0)
    //                    + x * (y1 - y0) / (x1 - x0)
    // =>
    //      a = (y1 - y0) / (x1 - x0)
    //      b = y0 - x0 * (y1 - y0) / (x1 - x0)
    return [
        (p1.y - p0.y) / (p1.x - p0.x),
        p0.y - (p0.x * (p1.y - p0.y)) / (p1.x - p0.x),
    ]
}

export const randNumber = (min: number, max: number) => {
    return min + Math.random() * max
}

export const randInt = (min: number, max: number) => {
    return Math.floor(randNumber(min, max))
}
