import React from 'react'
import styled, { keyframes } from 'styled-components/macro'
import { HEXAGON_FORMULA } from '../../../constants'
import theme from '../../../theme'
import { makeSvgPath } from '../../../utils'

const displace = theme.tileOverlay.hexagons.displace
const blurRadius = theme.tileOverlay.hexagons.blurRadius

const hexagonKeyframes = keyframes`
    0%, 3% {
        filter: blur(${blurRadius});
        transform: translate(${displace}px, ${displace}px);
    }
    8% {
        filter: none;
        transform: none;
    }
    21%, 23% {
        filter: blur(${blurRadius});
        transform: translate(${displace}px, ${displace}px);
    }
    27% {
        filter: none;
        transform: none;
    }
    68%, 71% {
        filter: blur(${blurRadius});
        transform: translate(${displace}px, ${displace}px);
    }
    75% {
        filter: none;
        transform: none;
    }
    98%, 100% {
        filter: blur(${blurRadius});
        transform: translate(${displace}px, ${displace}px);
    }
`

const Hexagon = styled.path`
    fill: transparent;
    stroke: white;
    stroke-width: ${theme.tileOverlay.hexagons.strokeWidth};
    animation: ${hexagonKeyframes} linear 1000ms infinite alternate-reverse;
    stroke-linecap: round;
`

const HexagonRedShadow = styled.path`
    transform: translate(0, 0);
    stroke: ${theme.colors.glitchRed};
    stroke-width: ${theme.tileOverlay.hexagons.strokeWidth};
    fill: none;
`

const HexagonBlueShadow = styled.path`
    transform: translate(${displace}px, ${displace}px);
    stroke: ${theme.colors.glitchBlue};
    stroke-width: ${theme.tileOverlay.hexagons.strokeWidth};
    fill: none;
`

interface Props {
    width: number
    height: number
}

const GlitchyHexagons: React.FunctionComponent<Props> = ({ width, height }) => {
    const svgHexagonPath = makeSvgPath(HEXAGON_FORMULA, [width, height])
    return (
        <>
            <HexagonRedShadow d={svgHexagonPath} />
            <HexagonBlueShadow d={svgHexagonPath} />
            <Hexagon d={svgHexagonPath} />
        </>
    )
}

export default React.memo(GlitchyHexagons)
