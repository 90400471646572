import styled, { keyframes } from 'styled-components/macro'
import theme from '../../../theme'
import SvgTextWrapped from './SvgTextWrapped'

const textKeyframes = keyframes`
    0% {
        stroke-dashoffset: -1000;
    }
    50% {
        stroke-dashoffset: 1000;
    }
    100% {
        stroke-dashoffset: -1000;
    }
`

export default styled(SvgTextWrapped)`
    text-transform: uppercase;
    text-shadow: 1px 1px 1px ${theme.colors.glitchBlue},
        -1px -1px 1px ${theme.colors.glitchRed}, -2px 2px 4px black,
        2px -2px 4px black;
    stroke-width: ${theme.tileOverlay.titleAndIcons.strokeWidth}px;
    stroke-linejoin: round;
    stroke-dasharray: 40 5;
    stroke-dashoffset: 0;
    animation: ${textKeyframes} linear 2000ms infinite alternate-reverse;
    stroke: ${theme.colors.titleWhite};
    user-select: none;
`
