import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import Hive from './Hive/Hive'
import { GOOGLE_ANALYTICS_ID } from '../constants'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import theme from '../theme'
import { refreshWindowDimensions } from '../store/windowDimensions'
import {
    selectPanZoomIsInitialized,
    selectWindowDimensions,
} from '../selectors'
import { setTransform } from '../store/panZoom'
import { useDeviceScaledSettings } from '../hooks'

if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize(GOOGLE_ANALYTICS_ID)
    ReactGA.pageview(window.location.pathname)
}

export const AppContainer = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    ${theme.mixins.scrollbar}
`

const App = () => {
    const dispatch = useDispatch()
    const isPanZoomInitialized = useSelector(selectPanZoomIsInitialized)
    const { width, height } = useSelector(selectWindowDimensions)
    const { initialScale, initialYOffsetRatio } = useDeviceScaledSettings()

    useEffect(() => {
        const dispatchRefreshWindowDimensions = () =>
            dispatch(refreshWindowDimensions())
        window.addEventListener('resize', dispatchRefreshWindowDimensions)
        return () => {
            window.removeEventListener(
                'resize',
                dispatchRefreshWindowDimensions
            )
        }
    }, [dispatch])

    // Center the hive, the first time we load the page, and we receive width and height.
    const shouldSetTransform: boolean = !!width && !!height
    useEffect(() => {
        dispatch(
            setTransform(initialScale, width / 2, height * initialYOffsetRatio)
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldSetTransform])

    return <AppContainer>{isPanZoomInitialized ? <Hive /> : null}</AppContainer>
}

export default App
