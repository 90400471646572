import React from 'react'
import { TILE_SIZE } from '../../constants'
import styled from 'styled-components/macro'

// Pointer events none, to avoid browser thinking you're trying to drag/drop an image
const ImageNoPointerEvents = styled.img`
    pointer-events: none;
    user-select: none;
`

interface Props {
    urls: Array<string>
}

const ThumbnailImage = ({ urls }: Props) => {
    const url = urls[0]
    return (
        <ImageNoPointerEvents width={TILE_SIZE} height={TILE_SIZE} src={url} />
    )
}

export default ThumbnailImage
