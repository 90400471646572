import React from 'react'
import styled from 'styled-components/macro'
import theme from '../theme'

// Spinner from https://tobiasahlin.com/spinkit/

const spinnerSize = '5rem'

const Container = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: space-around;
    opacity: 0.6;
`

const SpinnerAndText = styled.div`
    align-self: center;
`

const LoadingText = styled.div`
    text-align: center;
`

const Spinner = styled.div`
    width: ${spinnerSize};
    height: ${spinnerSize};
    ${theme.mixins.polygonClipPath}

    background-color: ${theme.colors.textLight};
    
    -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
    animation: sk-rotateplane 1.2s infinite ease-in-out;
      
    @-webkit-keyframes sk-rotateplane {
        0% { -webkit-transform: perspective(120px) }
        50% { -webkit-transform: perspective(120px) rotateY(180deg) }
        100% { -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg) }
    }
      
    @keyframes sk-rotateplane {
        0% { 
            transform: perspective(120px) rotateX(0deg) rotateY(0deg);
            -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg) 
        } 50% { 
            transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
            -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg) 
        } 100% { 
            transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
            -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        }
    }

`

const Loading = () => {
    return (
        <Container>
            <SpinnerAndText>
                <Spinner></Spinner>
                <LoadingText>Loading...</LoadingText>
            </SpinnerAndText>
        </Container>
    )
}

export default Loading
