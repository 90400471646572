import {
    DragState,
    dragReducer,
    DragActionTypes,
    initialDragState,
} from './drag'
import {
    PinchState,
    pinchReducer,
    PinchActionTypes,
    initialPinchState,
} from './pinch'
import {
    TransformMatrixState,
    initialTransformMatrixState,
} from './transform-matrix'
import { WheelActionTypes } from './wheel'

export type ActionTypes = DragActionTypes | PinchActionTypes | WheelActionTypes

export interface RootState {
    drag: DragState
    pinch: PinchState
    transformMatrix: TransformMatrixState
}

export const initialState: RootState = {
    drag: initialDragState,
    pinch: initialPinchState,
    transformMatrix: initialTransformMatrixState,
}

export default (state: RootState = initialState, action: ActionTypes) => {
    const updatedState = {
        ...state,
        pinch: pinchReducer(state.pinch, action as PinchActionTypes),
        drag: dragReducer(state.drag, action as DragActionTypes),
    }
    return updatedState
}
