import styled from 'styled-components/macro'
import theme from '../../../theme'
import SvgTextWrapped from './SvgTextWrapped'

export default styled(SvgTextWrapped)`
    text-shadow: 1px 1px 1px ${theme.colors.glitchBlueOpacity},
        -1px -1px 1px ${theme.colors.glitchRedOpacity},
        -1px 1px 1px rgba(255, 255, 255, 0.5);
    text-transform: uppercase;
    stroke-width: ${theme.tileOverlay.titleAndIcons.strokeWidth}px;
    stroke-linejoin: round;
    stroke-dasharray: 40 15 20 2 10 2;
    stroke-dashoffset: 0;
    stroke: ${theme.colors.titleGrey};
    user-select: none;
`
