import {
    RootState as InternalPanZoomRootState,
    initialState as initialValue,
} from '../panZoom/internal-reducer'
import { setScale, setTranslate } from '../panZoom/utils/math'
import { Transform } from '../models'
import deepEqual from 'lodash.isequal'

export const PAN_ZOOM_SET_INTERNAL = 'PAN_ZOOM_SET_INTERNAL'
export const PAN_ZOOM_SET_TRANSFORM = 'PAN_ZOOM_SET_TRANSFORM'

interface SetPanZoomInternal {
    type: typeof PAN_ZOOM_SET_INTERNAL
    payload: {
        internal: InternalPanZoomRootState
        transformMatrixChanged: boolean
    }
}

interface SetTransform {
    type: typeof PAN_ZOOM_SET_TRANSFORM
    payload: Transform
}

export type PanZoomStateTypes = SetPanZoomInternal | SetTransform

export const setPanZoomInternal = (
    internal: InternalPanZoomRootState,
    previousInternal: InternalPanZoomRootState
): PanZoomStateTypes => {
    const transformMatrixChanged = !deepEqual(
        internal.transformMatrix,
        previousInternal.transformMatrix
    )
    return {
        type: PAN_ZOOM_SET_INTERNAL,
        payload: { internal, transformMatrixChanged },
    }
}

export const setTransform = (
    scale: number,
    translateX: number,
    translateY: number
): PanZoomStateTypes => {
    return {
        type: PAN_ZOOM_SET_TRANSFORM,
        payload: {
            scale,
            translate: {
                x: translateX,
                y: translateY,
            },
        },
    }
}

export type PanZoomState = {
    internal: InternalPanZoomRootState
    isInitialized: boolean
}

const initialState = {
    internal: initialValue,
    isInitialized: false,
}

export function panZoomReducer(
    state = initialState,
    action: PanZoomStateTypes
): PanZoomState {
    switch (action.type) {
        case PAN_ZOOM_SET_INTERNAL:
            return {
                ...state,
                internal: action.payload.internal,
            }
        case PAN_ZOOM_SET_TRANSFORM:
            return {
                ...state,
                isInitialized: true,
                internal: {
                    ...state.internal,
                    transformMatrix: setTranslate(
                        setScale(
                            state.internal.transformMatrix,
                            action.payload.scale
                        ),
                        action.payload.translate.x,
                        action.payload.translate.y
                    ),
                },
            }
        default:
            return state
    }
}
