import { TileId } from '../models'

export const ZOOMED_TILE_ZOOMING_IN = 'ZOOMED_TILE_ZOOMING_IN'
export const ZOOMED_TILE_ZOOMING_OUT = 'ZOOMED_TILE_ZOOMING_OUT'
export const ZOOMED_TILE_UNSET_ZOOMED = 'ZOOMED_TILE_UNSET_ZOOMED'
export const ZOOMED_TILE_SET_ZOOMED = 'ZOOMED_TILE_SET_ZOOMED'

export enum ZoomStatus {
    NONE,
    ZOOMING_IN,
    ZOOMED,
    ZOOMING_OUT,
}

interface ZoomingInTile {
    type: typeof ZOOMED_TILE_ZOOMING_IN
    payload: TileId
}

interface UnsetZoomedTile {
    type: typeof ZOOMED_TILE_UNSET_ZOOMED
}

interface SetZoomed {
    type: typeof ZOOMED_TILE_SET_ZOOMED
}

interface ZoomingOutTile {
    type: typeof ZOOMED_TILE_ZOOMING_OUT
}

export type ZoomedTileActionTypes =
    | ZoomingInTile
    | UnsetZoomedTile
    | ZoomingOutTile
    | SetZoomed

export const zoomInTile = (tileId: TileId): ZoomedTileActionTypes => {
    return {
        type: ZOOMED_TILE_ZOOMING_IN,
        payload: tileId,
    }
}

export const setZoomed = (): ZoomedTileActionTypes => {
    return {
        type: ZOOMED_TILE_SET_ZOOMED,
    }
}

export const zoomOutTile = (): ZoomedTileActionTypes => {
    return {
        type: ZOOMED_TILE_ZOOMING_OUT,
    }
}

export const unsetZoomed = (): ZoomedTileActionTypes => {
    return {
        type: ZOOMED_TILE_UNSET_ZOOMED,
    }
}

export type ZoomedTileState = {
    tileId: TileId | null
    status: ZoomStatus
}

const initialState: ZoomedTileState = {
    tileId: null,
    status: ZoomStatus.NONE,
}

export function zoomedTileReducer(
    state = initialState,
    action: ZoomedTileActionTypes
): ZoomedTileState {
    switch (action.type) {
        case ZOOMED_TILE_ZOOMING_IN:
            return {
                tileId: action.payload,
                status: ZoomStatus.ZOOMING_IN,
            }
        case ZOOMED_TILE_SET_ZOOMED:
            return {
                ...state,
                status: ZoomStatus.ZOOMED,
            }
        case ZOOMED_TILE_ZOOMING_OUT:
            return {
                ...state,
                status: ZoomStatus.ZOOMING_OUT,
            }
        case ZOOMED_TILE_UNSET_ZOOMED:
            return {
                tileId: null,
                status: ZoomStatus.NONE,
            }
        default:
            return state
    }
}
