import { ViewportPoint, ViewportCoordinate } from '../types'

export const DRAG_START = 'DRAG_START'
export const DRAG = 'DRAG'
export const DRAG_END = 'DRAG_END'

interface DragStartAction {
    type: typeof DRAG_START
    payload: ViewportPoint
}

interface DragAction {
    type: typeof DRAG
    payload: ViewportPoint
}

interface DragEndAction {
    type: typeof DRAG_END
    payload: undefined
}

export type DragActionTypes = DragStartAction | DragAction | DragEndAction

export const dragStartAction = (
    x: ViewportCoordinate,
    y: ViewportCoordinate
): DragActionTypes => {
    return {
        type: DRAG_START,
        payload: { x, y },
    }
}

export const dragAction = (
    x: ViewportCoordinate,
    y: ViewportCoordinate
): DragActionTypes => {
    return {
        type: DRAG,
        payload: { x, y },
    }
}

export const dragEndAction = (): DragActionTypes => {
    return {
        type: DRAG_END,
        payload: undefined,
    }
}

export interface DragState {
    x: ViewportCoordinate | null
    y: ViewportCoordinate | null
    dragging: boolean
}

export const initialDragState: DragState = {
    x: null,
    y: null,
    dragging: false,
}

export const dragReducer = (
    state = initialDragState,
    action: DragActionTypes
) => {
    switch (action.type) {
        case DRAG_START:
            return {
                ...state,
                dragging: true,
                x: action.payload.x,
                y: action.payload.y,
            }
        case DRAG:
            return {
                ...state,
                x: action.payload.x,
                y: action.payload.y,
            }
        default:
            return {
                ...state,
                dragging: false,
                x: null,
                y: null,
            }
    }
}
