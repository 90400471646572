import React from 'react'
import { TileHtmlString } from '../../../pre-build/static'

interface Props {
    tileHtmlString: TileHtmlString
}

const ElementDiv = ({ tileHtmlString }: Props) => {
    return <div dangerouslySetInnerHTML={{ __html: tileHtmlString.html }}></div>
}

export default ElementDiv
