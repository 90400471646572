import React from 'react'
import { BibliographyKey } from '../../../models'
import InlineReference from '../../InlineReference'

interface Props {
    bibliographyKey: BibliographyKey
}

const ElementCitationLink = ({ bibliographyKey }: Props) => {
    return <InlineReference bibliographyKey={bibliographyKey} />
}

export default ElementCitationLink
