import React from 'react'
import styled, { keyframes } from 'styled-components/macro'
import { TileId } from '../../../models'
import theme from '../../../theme'
import { ICON_ORIGINAL_SIZE } from '../../icons'

export const ICON_SCALE = 1.5

const textKeyframes = keyframes`
    0% {
        stroke-dashoffset: -1000;
    }
    50% {
        stroke-dashoffset: 1000;
    }
    100% {
        stroke-dashoffset: -1000;
    }
`

export const IconGroup = styled.g`
    transform: scale(${ICON_SCALE});
    filter: drop-shadow(1px 1px 1px ${theme.colors.glitchBlue})
        drop-shadow(-1px -1px 1px ${theme.colors.glitchRed})
        drop-shadow(-1px 1px 1px black);
    stroke-width: ${theme.tileOverlay.titleAndIcons.strokeWidth / ICON_SCALE}px;
    stroke-linejoin: round;
    stroke-dasharray: 40 5;
    stroke-dashoffset: 0;
    animation: ${textKeyframes} linear 2000ms infinite alternate-reverse;
    stroke: white;
    fill: none;
`

const ButtonGroup = styled.g`
    pointer-events: all;
    cursor: pointer;
    &:hover {
    }

    & > g {
        transform: scale();
    }
`

interface PassThroughProps {
    width: number
    height: number
    x: number
}

export interface ButtonContainerProps extends PassThroughProps {
    onClick?: () => void
}

export interface ButtonProps extends PassThroughProps {
    tileId: TileId
}

export const ButtonContainer: React.FunctionComponent<React.PropsWithChildren<
    ButtonContainerProps
>> = ({ width, height, x, children, onClick }) => {
    const xIcon = width / 2 - (ICON_ORIGINAL_SIZE * ICON_SCALE) / 2
    const yIcon = theme.tileOverlay.buttons.paddingTop

    const iconPositionStyle: React.CSSProperties = {
        transform: `translate(${xIcon}px, ${yIcon}px)`,
    }
    const buttonPositionStyle: React.CSSProperties = {
        transform: `translateX(${x}px)`,
    }

    return (
        <ButtonGroup onClick={onClick} style={buttonPositionStyle}>
            <g style={iconPositionStyle}>{children}</g>
        </ButtonGroup>
    )
}

export default ButtonContainer
