import React from 'react'
import { TileId } from '../../../models'
import ButtonsSoundOnOff from './ButtonsSoundOnOff'
import ButtonsReadMore from './ButtonsReadMore'

export type BUTTON_TYPE = 'READ_MORE' | 'SOUND_ON_OFF'

interface Props {
    buttons: Array<BUTTON_TYPE>
    tileId: TileId
    width: number
    height: number
    y: number
}

const Buttons: React.FunctionComponent<Props> = ({
    buttons,
    tileId,
    width,
    height,
    y,
}) => {
    const buttonSlotWidth = width / buttons.length
    const buttonsElems = buttons.map((buttonType, i) => {
        if (buttonType === 'SOUND_ON_OFF') {
            return (
                <ButtonsSoundOnOff
                    tileId={tileId}
                    width={buttonSlotWidth}
                    height={height}
                    x={i * buttonSlotWidth}
                    key={i}
                />
            )
        } else if (buttonType === 'READ_MORE') {
            return (
                <ButtonsReadMore
                    tileId={tileId}
                    width={buttonSlotWidth}
                    height={height}
                    x={i * buttonSlotWidth}
                    key={i}
                />
            )
        } else {
            throw new Error(`unexpected button type ${buttonType}`)
        }
    })

    const positionStyle: React.CSSProperties = {
        transform: `translateY(${y}px)`,
    }

    return <g style={positionStyle}>{buttonsElems}</g>
}

export default React.memo(Buttons)
