import React from 'react'
import { BibliographyEntry } from '../../models'
import styled from 'styled-components/macro'
import theme from '../../theme'
import { useDispatch, useSelector } from 'react-redux'
import { removeReferenceEntry } from '../../store/referenceViewer'
import { selectReferenceViewerEntryIndex } from '../../selectors'
import { RootState } from '../../store'

interface Props {
    bibliographyEntry: BibliographyEntry
}

export const Container = styled.div`
    display: flex;

    a {
        color: ${theme.colors.textLinkLight};
    }
`

const IndexNumber = styled.div`
    text-decoration: underline;
    margin-right: ${theme.spacing.space1}rem;
`

const Citation = styled.div`
    flex: 1;
    overflow-wrap: anywhere;
`

const CloseButton = styled.button`
    border: none;
    background-color: transparent;
    align-self: baseline;
    font-size: 150%;
    margin-left: ${theme.spacing.space1}rem;
`

const BibliographyEntryComponent = ({ bibliographyEntry }: Props) => {
    const dispatch = useDispatch()
    const referenceViewerIndex = useSelector((state: RootState) =>
        selectReferenceViewerEntryIndex(state, bibliographyEntry.id)
    )
    const onClose = () => dispatch(removeReferenceEntry(bibliographyEntry.id))
    return (
        <Container>
            <IndexNumber>{referenceViewerIndex}</IndexNumber>
            <Citation
                dangerouslySetInnerHTML={{ __html: bibliographyEntry.citation }}
            ></Citation>
            <CloseButton onClick={onClose}>X</CloseButton>
        </Container>
    )
}

export default BibliographyEntryComponent
