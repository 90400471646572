export const SVG_TEXT_SIZE_SET = 'SVG_TEXT_SIZE_SET'

interface SetSize {
    type: typeof SVG_TEXT_SIZE_SET
    payload: SvgTextSizeState
}

export type SvgTextSizeTypes = SetSize

export const setSize = (
    charWidth: number,
    lineHeight: number
): SvgTextSizeTypes => {
    return {
        type: SVG_TEXT_SIZE_SET,
        payload: { charWidth, lineHeight },
    }
}

export interface SvgTextSizeState {
    charWidth: number
    lineHeight: number
}

const initialState: SvgTextSizeState = {
    charWidth: 0,
    lineHeight: 0,
}

export function svgTextSizeReducer(
    state = initialState,
    action: SvgTextSizeTypes
): SvgTextSizeState {
    switch (action.type) {
        case SVG_TEXT_SIZE_SET:
            return action.payload
        default:
            return state
    }
}
