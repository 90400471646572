import React from 'react'
import styled from 'styled-components/macro'
import theme from '../../theme'

const TagContainer = styled.ul`
    text-align: right;
    padding: ${theme.spacing.space1}rem 0;
`

const Tag = styled.li`
    display: inline-block;
    margin: 0 0.5rem;
    color: ${theme.colors.textDark};
    background-color: ${theme.colors.tagsBackground};
`

interface Props {
    tags: Array<string>
}

const Tags = ({ tags }: Props) => {
    const tagsElems = tags.map((tag) => <Tag key={tag}>#{tag}</Tag>)
    return <TagContainer>{tagsElems}</TagContainer>
}

export default Tags
