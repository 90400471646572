import {
    FrontendTile as BaseFrontendTile,
    HexPoint,
    MediaObjectType,
} from './pre-build/static'
import { TILES_CONTENT_ROOT_URL } from './constants'

export type TileId = string
export type MediaObjectId = string
export type BibliographyKey = string

export enum TileType {
    chapter,
    tile,
}

export interface Transform {
    scale: number
    translate: {
        x: number
        y: number
    }
}

export interface BoundingBox {
    topLeft: Point
    bottomRight: Point
}

export interface HexBoundingBox {
    topLeft: HexPoint
    bottomRight: HexPoint
}

export interface Point {
    x: number
    y: number
}

export interface FrontendTile extends Omit<BaseFrontendTile, 'type'> {
    type: TileType
    position: Point
    thumbnails: Array<string>
    thumbnailType: MediaObjectType
}

export interface FrontendMediaObject {
    id: MediaObjectId
    mediaType: MediaObjectType
    urls: Array<string>
}

export const tileContentUrl = (tileId: TileId) => {
    return `${TILES_CONTENT_ROOT_URL}/${tileId}.json`
}

export interface BibliographyEntry {
    id: BibliographyKey
    citation: string
}

type SettingRange = [number, number]

export interface DeviceScaledSettings {
    // Used to compute the scaling of other values.
    // left is for small screen, right for big screen
    width: SettingRange

    maxScale: SettingRange
    minScale: SettingRange
    initialScale: SettingRange

    // The ratio in height of the page where to position the intro tile
    // on page load. 0.5 means tile will be centered vertically
    initialYOffsetRatio: SettingRange

    zoomToPageScaleThreshold: SettingRange
    zoomToPageScale: SettingRange
    zoomToPageScaleBack: SettingRange

    // The multiplier for the font size of the title
    titleFontSizeRatio: SettingRange
}
