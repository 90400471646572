import React from 'react'
import { clickedTileSetSoundOn } from '../../../store/clickedTile'
import { useDispatch, useSelector } from 'react-redux'
import { selectSoundTileId } from '../../../selectors'
import ButtonContainer, { ButtonProps, IconGroup } from './ButtonContainer'
import { VolumeOffIcon, VolumeOnIcon } from '../../icons'

const SoundOnOffButton: React.FunctionComponent<ButtonProps> = ({
    tileId,
    ...passThroughProps
}) => {
    const dispatch = useDispatch()
    const currentSoundTile = useSelector(selectSoundTileId)

    const _soundOnOffClicked = () =>
        dispatch(
            clickedTileSetSoundOn(isSoundOn ? null : tileId, currentSoundTile)
        )

    const isSoundOn = currentSoundTile === tileId
    const volumeIcon = isSoundOn ? <VolumeOnIcon /> : <VolumeOffIcon />

    return (
        <ButtonContainer onClick={_soundOnOffClicked} {...passThroughProps}>
            <IconGroup>{volumeIcon}</IconGroup>
        </ButtonContainer>
    )
}

export default SoundOnOffButton
