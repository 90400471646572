import { convertViewportCoordsToUniverse } from './utils/math'
import { RootState } from './internal-reducer'
import { fromObject, translate, transform, scale } from 'transformation-matrix'
import { UniverseCoordinate, ViewportCoordinate } from './types'
import { BoundingBox } from '../models'

export const updatePanning = (
    state: RootState,
    viewportX: ViewportCoordinate,
    viewportY: ViewportCoordinate,
    limits?: BoundingBox
) => {
    const { x: endX, y: endY } = state.drag

    const start = convertViewportCoordsToUniverse(
        state.transformMatrix,
        endX || 0,
        endY || 0
    )
    const end = convertViewportCoordsToUniverse(
        state.transformMatrix,
        viewportX,
        viewportY
    )

    let deltaX = start.x - end.x
    let deltaY = start.y - end.y

    if (limits) {
        // If (deltaX < 0), moving towards left
        //      AND we reached the limit on that side
        //      AND limit is not reached on the other side
        //          If hive is zoomed out, and there is space on both sides, we shouldn't block movement in either direction.
        if (
            (deltaX < 0 &&
                deltaX < limits.topLeft.x &&
                limits.bottomRight.x > 0) ||
            // If (deltaX > 0), moving towards right, etc ...
            (deltaX > 0 &&
                deltaX > limits.bottomRight.x &&
                limits.topLeft.x < 0)
        ) {
            deltaX = 0
        }
        if (
            (deltaY < 0 &&
                deltaY < limits.topLeft.y &&
                limits.bottomRight.y > 0) ||
            (deltaY > 0 &&
                deltaY > limits.bottomRight.y &&
                limits.topLeft.y < 0)
        ) {
            deltaY = 0
        }
    }

    const matrix = transform(
        fromObject(state.transformMatrix),
        translate(-deltaX, -deltaY)
    )

    return {
        ...state,
        transformMatrix: matrix,
    }
}

export const zoomToUniversePoint = (
    state: RootState,
    universeX: UniverseCoordinate,
    universeY: UniverseCoordinate,
    scaleFactor: number
) => {
    const matrix = transform(
        fromObject(state.transformMatrix),
        translate(universeX, universeY),
        scale(scaleFactor, scaleFactor),
        translate(-universeX, -universeY)
    )
    return {
        ...state,
        transformMatrix: matrix,
    }
}

export const zoomToViewportPoint = (
    state: RootState,
    x: ViewportCoordinate,
    y: ViewportCoordinate,
    scaleFactor: number
) => {
    const { x: universeX, y: universeY } = convertViewportCoordsToUniverse(
        state.transformMatrix,
        x,
        y
    )
    return zoomToUniversePoint(state, universeX, universeY, scaleFactor)
}
