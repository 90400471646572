import { applyToPoint, inverse } from 'transformation-matrix'
import { TransformMatrix, ViewportCoordinate, UniversePoint } from '../types'

export const convertViewportCoordsToUniverse = (
    transformMatrix: TransformMatrix,
    viewportX: ViewportCoordinate,
    viewportY: ViewportCoordinate
) => {
    const inverseMatrix = inverse(transformMatrix)
    return applyToPoint(inverseMatrix, {
        x: viewportX,
        y: viewportY,
    }) as UniversePoint
}

export const getScale = (transformMatrix: TransformMatrix) => transformMatrix.a

export const setScale = (transformMatrix: TransformMatrix, scale: number) => ({
    ...transformMatrix,
    a: scale,
    d: scale,
})

export const getTranslate = (transformMatrix: TransformMatrix) => ({
    x: transformMatrix.e,
    y: transformMatrix.f,
})

export const setTranslate = (
    transformMatrix: TransformMatrix,
    x: number,
    y: number
) => ({
    ...transformMatrix,
    e: x,
    f: y,
})

export const mapRange = (
    value: number,
    sourceStart: number,
    sourceEnd: number,
    targetStart: number,
    targetEnd: number
) => {
    return (
        targetStart +
        ((targetEnd - targetStart) * (value - sourceStart)) /
            (sourceEnd - sourceStart)
    )
}
