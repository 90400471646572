import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { TileId, tileContentUrl } from '../../models'
import { useDispatch, useSelector } from 'react-redux'
import { zoomOutTile, ZoomStatus } from '../../store/zoomedTile'
import { useGetJson } from '../../hooks'
import Loading from '../Loading'
import {
    FrontendTileContent,
    FrontendChapterTileContent,
} from '../../pre-build/static'
import theme from '../../theme'
import { selectZoomedTileStatus } from '../../selectors'
import { PAGE_ZOOM_DURATION } from '../../constants'
import ContentsGeneric from './ContentsGeneric'

interface OuterContainerProps {
    hasBackground: boolean
}

interface InnerContainerProps {
    hasOpacity: boolean
}

const PageOuterContainer = styled.div<OuterContainerProps>`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    ${theme.mixins.scrollbar}

    transition-timing-function: linear;
    transition-property: background-color;
    transition-duration: ${PAGE_ZOOM_DURATION}s;
    background-color: ${(props) =>
        props.hasBackground ? theme.colors.tilePageBackground : 'transparent'};

    color: ${theme.colors.textLight};
    button {
        color: ${theme.colors.textLight};
    }
`

const PageInnerContainer = styled.div<InnerContainerProps>`
    max-width: 900px;
    margin: auto;
    padding: ${theme.spacing.space1}rem;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;

    transition-timing-function: linear;
    transition-property: opacity;
    transition-duration: ${PAGE_ZOOM_DURATION * 0.5}s;
    opacity: ${(props) => (props.hasOpacity ? '1' : '0')};
`

const LoadingContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`

interface Props {
    tileId: TileId
}

const TilePage = ({ tileId }: Props) => {
    const dispatch = useDispatch()
    const zoomStatus = useSelector(selectZoomedTileStatus)

    const { response, error, isLoading } = useGetJson<
        FrontendTileContent | FrontendChapterTileContent
    >(tileContentUrl(tileId))
    if (error) {
        console.error(error)
    }

    const _onBackClicked = (event: React.SyntheticEvent) => {
        event.preventDefault()
        dispatch(zoomOutTile())
    }

    const _onOuterContainerClicked = (event: React.SyntheticEvent) => {
        if (zoomStatus === ZoomStatus.ZOOMED) {
            _onBackClicked(event)
        }
    }

    const _preventClickBubble = (event: React.SyntheticEvent) => {
        event.stopPropagation()
    }

    const isInnerContentReadyToRender =
        !isLoading &&
        !error &&
        response &&
        [ZoomStatus.ZOOMED, ZoomStatus.ZOOMING_OUT].includes(zoomStatus)

    // We need to first have opacity 0 at first render of the content, and immediately after provoke a re-render
    // with opacity 1 to trigger the css transitions, same for background.
    const [innerContentInitialized, setInnerContentInitialized] = useState<
        boolean
    >(false)
    const [outerContainerInitialized, setOuterContainerInitialized] = useState<
        boolean
    >(false)
    // No idea why this works better with a timeout
    useEffect(() => {
        setTimeout(() => setOuterContainerInitialized(true), 1)
        if (isInnerContentReadyToRender) {
            setTimeout(() => setInnerContentInitialized(true), 1)
        }
    }, [zoomStatus, isInnerContentReadyToRender])

    let innerContent: JSX.Element | null = null
    let loadingElem: JSX.Element | null = null
    if (isInnerContentReadyToRender) {
        innerContent = (
            <ContentsGeneric
                tileId={tileId}
                onBackClicked={_onBackClicked}
                tileContent={response!}
            />
        )
    } else {
        loadingElem = (
            <LoadingContainer>
                <Loading />
            </LoadingContainer>
        )
    }

    const innerContentHasOpacity =
        innerContentInitialized && zoomStatus !== ZoomStatus.ZOOMING_OUT
    const outerContainerHasBackground =
        outerContainerInitialized && zoomStatus !== ZoomStatus.ZOOMING_OUT

    return (
        <PageOuterContainer
            onClick={_onOuterContainerClicked}
            hasBackground={outerContainerHasBackground}
        >
            {/* Prevent click from bubbling to the outer container
            so it doesn't close the page */}
            <PageInnerContainer
                onClick={_preventClickBubble}
                hasOpacity={innerContentHasOpacity}
            >
                {innerContent}
            </PageInnerContainer>
            {loadingElem}
        </PageOuterContainer>
    )
}

export default React.memo(TilePage)
