import { TileId } from './models'

export const splitTextInLines = (
    text: string,
    charWidth: number,
    containerWidth: number
) => {
    const tokenized = text.split(' ').filter((word) => word.length)
    const lines: Array<Array<string>> = []
    let currentLine: Array<string> = []
    let currentLineLength = 0
    while (tokenized.length) {
        const word = tokenized.shift()!

        // If the word is too long to fit in current line
        if (currentLineLength + charWidth * word.length > containerWidth) {
            // If there are already word in current line, create a new line
            if (currentLine.length) {
                lines.push(currentLine)
                currentLine = [word]
                // +1 for space
                currentLineLength = (word.length + 1) * charWidth

                // If there aren't already words, create a line with only that word even if too long
            } else {
                lines.push([word])
            }
        } else {
            currentLine.push(word)
            currentLineLength += (word.length + 1) * charWidth
        }
    }
    if (currentLine.length) {
        lines.push(currentLine)
    }
    return lines.map((words) => words.join(' '))
}

export const buildThumbnailVideoId = (tileId: TileId) =>
    `thumbnail-video-${tileId}`

export const setHtmlVideoMuted = (tileId: TileId, muted: boolean) => {
    const videoId = buildThumbnailVideoId(tileId)
    const videoElement = document.querySelector(
        `#${videoId}`
    ) as HTMLVideoElement
    if (!videoElement) {
        console.error(`video not found ${videoId}`)
        return
    }
    videoElement.muted = muted
}

// REF : https://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript
export const isTouchDevice = () => {
    return (
        'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        (navigator as any).msMaxTouchPoints > 0
    )
}

// Make SVG path `d`.
// `points` coordinates  are given in ratios of the container size.
export const makeSvgPath = (
    points: Array<[number, number]>,
    containerDimensions: [number, number],
    close: boolean = true
) => {
    const [containerWidth, containerHeight] = containerDimensions
    if (close) {
        points = points.concat([points[0]])
    }
    return points
        .map(([xRatio, yRatio], i) => {
            const x = xRatio * containerWidth
            const y = yRatio * containerHeight
            if (i === 0) {
                return `M ${x} ${y}`
            } else {
                return `L ${x} ${y}`
            }
        })
        .join(' ')
}

export const makeRgbaColor = ([r, g, b]: [number, number, number], a: number) =>
    `rgba(${r}, ${g}, ${b}, ${a})`
