import React from 'react'
import styled from 'styled-components/macro'
import { TILE_SIZE } from '../../../constants'
import { FrontendTile } from '../../../models'
import theme from '../../../theme'
import GlitchyHexagons from './GlitchyHexagons'
import GlitchyTitle from './GlitchyTitle'
import Buttons, { BUTTON_TYPE } from './Buttons'
import StaticTitle from './StaticTitle'

export type MODE = 'HALO' | 'FULL' | 'CHAPTER'

const hexagonsStrokeWidth = theme.tileOverlay.hexagons.strokeWidth
const hexagonsDisplace = theme.tileOverlay.hexagons.displace

const Svg = styled.svg`
    position: absolute;
    width: calc(100% + ${hexagonsStrokeWidth * 2}px);
    height: calc(100% + ${hexagonsStrokeWidth * 2}px);
    left: -${hexagonsStrokeWidth}px;
    top: -${hexagonsStrokeWidth}px;
    z-index: 10;
    user-select: none;
`

interface Props {
    tile: FrontendTile
    buttons: Array<BUTTON_TYPE>
    isShowingButtons: boolean
    mode: MODE
}

const Overlay: React.FunctionComponent<Props> = ({
    tile,
    buttons,
    mode = 'FULL',
}) => {
    const containerWidth = TILE_SIZE
    const containerHeight = TILE_SIZE

    const titleHeight =
        containerHeight * (1 - theme.tileOverlay.buttons.containerHeight)
    const titleXPadding =
        theme.tileOverlay.hexagons.strokeWidth / 2 +
        theme.tileOverlay.title.xPadding

    // stroke width is centered around the path, so only half the stroke width
    // would be bleeding outside the viewbox if not adjusted.
    const viewBoxOffset = hexagonsStrokeWidth / 2 + hexagonsDisplace
    const viewBoxWidth =
        containerWidth + hexagonsStrokeWidth + 2 * hexagonsDisplace
    const viewBoxHeight =
        containerHeight + hexagonsStrokeWidth + 2 * hexagonsDisplace

    return (
        <Svg
            viewBox={`-${viewBoxOffset} -${viewBoxOffset} ${viewBoxWidth} ${viewBoxHeight}`}
        >
            {['HALO', 'FULL'].includes(mode) ? (
                <GlitchyHexagons
                    width={containerWidth}
                    height={containerHeight}
                />
            ) : null}

            {['FULL'].includes(mode) ? (
                <GlitchyTitle
                    text={tile.title}
                    containerWidth={containerWidth}
                    containerHeight={titleHeight}
                    centerY={containerHeight / 2}
                    xPadding={titleXPadding}
                />
            ) : null}

            {['CHAPTER'].includes(mode) ? (
                <StaticTitle
                    text={tile.title}
                    containerWidth={containerWidth}
                    containerHeight={titleHeight}
                    centerY={containerHeight / 2}
                    xPadding={titleXPadding}
                />
            ) : null}

            {['FULL'].includes(mode) ? (
                <Buttons
                    buttons={buttons}
                    tileId={tile.id}
                    width={containerWidth}
                    height={
                        containerHeight *
                        theme.tileOverlay.buttons.containerHeight
                    }
                    y={titleHeight}
                />
            ) : null}
        </Svg>
    )
}

export default React.memo(Overlay)
