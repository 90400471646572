import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App'
import { Provider } from 'react-redux'
import { store } from './store'
import * as serviceWorker from './serviceWorker'
import { createGlobalStyle } from 'styled-components'
import SvgTextSizeMeasurement from './components/SvgTextSizeMeasurement'

const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    button {
        cursor: pointer;
    }

    body {
        background-color: black;
        overflow: hidden;
        /* font-family: 'Inconsolata', monospace;
        font-family: 'Anonymous Pro', monospace;
        font-family: 'Cutive Mono', monospace; */
        font-family: 'Fira Code', monospace;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 20px;
    }

    @media screen and (max-width: 1000px) {
        body {
            font-size: 15px;
        }
    }

    @media screen and (max-width: 600px) {
        body {
            font-size: 10px;
        }
    }

    html,
    body,
    #root {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
`

ReactDOM.render(
    <React.StrictMode>
        <GlobalStyle />
        <Provider store={store}>
            <SvgTextSizeMeasurement />
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
