import React, { useEffect } from 'react'
import isDeepEqual from 'lodash.isequal'
import {
    getMousePosition,
    getWheelPositionAndScale,
    getPinchPoints,
    isMultiTouch,
    getTouchPosition,
} from './utils/events'

import internalReducer, { RootState, ActionTypes } from './internal-reducer'
import {
    dragStartAction,
    dragAction,
    dragEndAction,
} from './internal-reducer/drag'
import {
    pinchStartAction,
    pinchAction,
    pinchEndAction,
} from './internal-reducer/pinch'
import { wheelAction } from './internal-reducer/wheel'

export const usePanZoom = (
    panZoomRef: React.RefObject<HTMLElement>,
    reducer: (state: RootState, action: ActionTypes) => RootState,
    onStateChange: (newState: RootState, state: RootState) => void,
    state: RootState,
    scaleFactorOnWheel: number = 1.06
) => {
    let updatedState = { ...state }

    const setState = (newState: RootState) => {
        if (isDeepEqual(state, newState)) {
            return
        }
        onStateChange(newState, state)
    }

    useEffect(() => {
        // Make sure that we stop dragging when mouse is released outside the element
        const windowMouseUp = (_: MouseEvent) => {
            setState(internalReducer(updatedState, dragEndAction()))
        }
        window.addEventListener('mouseup', windowMouseUp)

        // To disables the bounce effect in ios Safari
        // ref : https://stackoverflow.com/questions/7768269/ipad-safari-disable-scrolling-and-bounce-effect
        const disableBounce = (ev: TouchEvent) => ev.preventDefault()
        let panZoomContainer = panZoomRef.current
        if (panZoomContainer) {
            panZoomContainer.addEventListener('touchmove', disableBounce, {
                passive: false,
            })
        }

        return () => {
            if (panZoomContainer) {
                panZoomContainer.removeEventListener('touchmove', disableBounce)
            }
            window.removeEventListener('mouseup', windowMouseUp)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state])

    const onMouseDown = (event: React.MouseEvent) => {
        const { x, y } = getMousePosition(event, panZoomRef.current!)
        updatedState = internalReducer(updatedState, dragStartAction(x, y))
        // event.preventDefault()
        setState(updatedState)
    }

    const onTouchStart = (event: React.TouchEvent) => {
        if (isMultiTouch(event)) {
            const [viewportPoint, initialPinchPointDistance] = getPinchPoints(
                event,
                panZoomRef.current!
            )
            const scaleFactor = 1
            updatedState = internalReducer(
                updatedState,
                pinchStartAction(initialPinchPointDistance)
            )
            updatedState = reducer(
                updatedState,
                pinchAction(
                    viewportPoint.x,
                    viewportPoint.y,
                    scaleFactor,
                    initialPinchPointDistance
                )
            )
            updatedState = internalReducer(
                updatedState,
                pinchAction(
                    viewportPoint.x,
                    viewportPoint.y,
                    scaleFactor,
                    initialPinchPointDistance
                )
            )
        } else {
            const { x, y } = getTouchPosition(event, panZoomRef.current!)
            updatedState = internalReducer(updatedState, dragStartAction(x, y))
        }

        // event.stopPropagation()
        // event.preventDefault()
        setState(updatedState)
    }

    const onMouseMove = (event: React.MouseEvent) => {
        if (updatedState.drag.dragging) {
            const { x, y } = getMousePosition(event, panZoomRef.current!)
            updatedState = reducer(updatedState, dragAction(x, y))
            updatedState = internalReducer(updatedState, dragAction(x, y))
            // event.preventDefault();
            setState(updatedState)
        }
    }

    const onTouchMove = (event: React.TouchEvent) => {
        if (isMultiTouch(event)) {
            if (updatedState.pinch.pinching) {
                const [viewportPoint, newPinchPointDistance] = getPinchPoints(
                    event,
                    panZoomRef.current!
                )
                const scaleFactor =
                    newPinchPointDistance /
                    updatedState.pinch.pinchPointDistance!
                updatedState = reducer(
                    updatedState,
                    pinchAction(
                        viewportPoint.x,
                        viewportPoint.y,
                        scaleFactor,
                        newPinchPointDistance
                    )
                )
                updatedState = internalReducer(
                    updatedState,
                    pinchAction(
                        viewportPoint.x,
                        viewportPoint.y,
                        scaleFactor,
                        newPinchPointDistance
                    )
                )
            }
        } else {
            if (updatedState.drag.dragging) {
                const { x, y } = getTouchPosition(event, panZoomRef.current!)
                updatedState = reducer(updatedState, dragAction(x, y))
                updatedState = internalReducer(updatedState, dragAction(x, y))
            }
        }

        // event.stopPropagation();
        // event.preventDefault();
        setState(updatedState)
    }

    const onWheel = (event: React.WheelEvent) => {
        const [viewportPoint, scaleFactor] = getWheelPositionAndScale(
            scaleFactorOnWheel,
            event,
            panZoomRef.current!
        )
        updatedState = reducer(
            updatedState,
            wheelAction(viewportPoint.x, viewportPoint.y, scaleFactor)
        )
        // event.preventDefault();
        setState(updatedState)
    }

    const onMouseUp = (event: React.MouseEvent) => {
        const { x, y } = getMousePosition(event, panZoomRef.current!)
        updatedState = reducer(updatedState, dragAction(x, y))
        updatedState = internalReducer(updatedState, dragAction(x, y))
        updatedState = internalReducer(updatedState, dragEndAction())
        // event.preventDefault();
        setState(updatedState)
    }

    const onTouchEnd = (event: React.TouchEvent) => {
        if (!isMultiTouch(event)) {
            if (updatedState.pinch.pinching) {
                updatedState = internalReducer(updatedState, pinchEndAction())
            } else if (updatedState.drag.dragging) {
                const { x, y } = getTouchPosition(event, panZoomRef.current!)
                updatedState = reducer(updatedState, dragAction(x, y))
                updatedState = internalReducer(updatedState, dragAction(x, y))
                updatedState = internalReducer(updatedState, dragEndAction())
            }
            // event.stopPropagation()
            // event.preventDefault()
        }
        setState(updatedState)
    }

    return {
        onMouseDown,
        onTouchStart,
        onMouseMove,
        onTouchMove,
        onWheel,
        onMouseUp,
        onTouchEnd,
    }
}
