// REF : https://tympanus.net/codrops-playground/yoksel/Y0FaGpOi/editor/html,css,result
import React from 'react'
import styled, { keyframes, css } from 'styled-components/macro'
import { INTRO_TILE_ID, SITE_TITLE, TILE_SIZE } from '../../constants'
import { ICON_ORIGINAL_SIZE, ReadMoreIcon } from '../icons'
import { useDeviceScaledSettings, useRequestZoomToTile } from '../../hooks'
import { FrontendTile } from '../../models'
import theme from '../../theme'
import HexagonContainer from './HexagonContainer'
import TilePagePortal from '../TilePagePortal'
import TilePage from '../TilePage'
import { useSelector } from 'react-redux'
import { selectZoomedTileId } from '../../selectors'
import TileEmpty from './TileEmpty'

const ANIMATION_DURATION = 7000
const VIEWBOX_W = TILE_SIZE * 3
const VIEWBOX_H = TILE_SIZE
const ICON_SCALE = 2
const ICON_SCALED_SIZE = ICON_ORIGINAL_SIZE * ICON_SCALE
const STROKE_WIDTH = 5

const Container = styled(HexagonContainer)`
    z-index: ${theme.zIndex.tileIntro};
`

const Svg = styled.svg`
    height: 100%;
    transform: translateX(-50%);
    position: absolute;
    top: 0;
    left: 50%;
`

const textKeyframes = keyframes`
    0% {
        stroke-dashoffset: -1000;
    }
    50% {
        stroke-dashoffset: 1000;
    }
    100% {
        stroke-dashoffset: -1000;
    }
`

const baseStyle = css`
    stroke: ${theme.colors.titleWhite};
    fill: black;
    animation: ${textKeyframes} ${ANIMATION_DURATION}ms infinite linear;
    stroke-width: ${STROKE_WIDTH};
    stroke-linejoin: round;
    stroke-dasharray: 60 10;
    stroke-dashoffset: 0;
`

const ButtonGroup = styled.g`
    ${baseStyle}
    stroke-width: ${STROKE_WIDTH / ICON_SCALE};
    fill: transparent;
    filter: drop-shadow(1px 1px 1px ${
        theme.colors.glitchBlue
    }) drop-shadow(-1px -1px 1px ${theme.colors.glitchRed});
    cursor: pointer;
    pointer-events: all;

    & > * {
        transform: scale(${ICON_SCALE});
    }
`

const Text = styled.text<{ fontSizeRatio: number }>`
    ${baseStyle}
    text-anchor: middle;
    letter-spacing: 0.3em;
    alignment-baseline: central;
    text-shadow: 1px 1px 1px ${theme.colors.glitchBlue},
        -1px -1px 1px ${theme.colors.glitchRed};
    font-size: ${(props) =>
        Math.round(props.fontSizeRatio * theme.fontSizes.basePx)}px;
    user-select: none;
`

interface Props {
    tile: FrontendTile
}

const TileIntro: React.FunctionComponent<Props> = ({ tile }) => {
    const isZoomed = useSelector(selectZoomedTileId) === INTRO_TILE_ID
    const { titleFontSizeRatio } = useDeviceScaledSettings()
    const buttonPositionStyle: React.CSSProperties = {
        transform: `translate(${VIEWBOX_W / 2 - ICON_SCALED_SIZE / 2}px, ${
            VIEWBOX_H - ICON_SCALED_SIZE * 1.5
        }px)`,
    }
    const [zoomToTileEffect] = useRequestZoomToTile(INTRO_TILE_ID)

    return (
        <>
        <TileEmpty hexPoint={tile.hex} />
        <Container position={tile.position}>
            <Svg viewBox={`0 0 ${VIEWBOX_W} ${VIEWBOX_H}`}>
                <Text
                    x={VIEWBOX_W / 2}
                    y={VIEWBOX_H / 2}
                    fontSizeRatio={titleFontSizeRatio}
                >
                    {SITE_TITLE}
                </Text>
                <ButtonGroup
                    style={buttonPositionStyle}
                    onClick={zoomToTileEffect}
                >
                    <ReadMoreIcon />
                </ButtonGroup>
            </Svg>
            {isZoomed ? (
                <TilePagePortal>
                    <TilePage tileId={INTRO_TILE_ID} />
                </TilePagePortal>
            ) : null}
        </Container>
        </>
    )
}

export default React.memo(TileIntro)
