import { mapRange } from './math'
import { ViewportPoint, ViewportCoordinate } from '../types'

export const getMousePosition = (
    event: React.MouseEvent,
    element: HTMLElement
): ViewportPoint => {
    const { left, top } = element.getBoundingClientRect()
    const x = event.clientX - Math.round(left)
    const y = event.clientY - Math.round(top)
    return { x, y }
}

export const getWheelPositionAndScale = (
    scaleFactorOnWheel: number,
    event: React.WheelEvent,
    element: HTMLElement
): [ViewportPoint, number] => {
    const delta = Math.max(-1, Math.min(1, event.deltaY))
    const scaleFactor = mapRange(
        delta,
        -1,
        1,
        scaleFactorOnWheel,
        1 / scaleFactorOnWheel
    )
    return [getMousePosition(event, element), scaleFactor]
}

export const getTouchPosition = (
    event: React.TouchEvent,
    element: HTMLElement
): ViewportPoint => {
    const touch =
        event.touches.length > 0 ? event.touches[0] : event.changedTouches[0]
    const { left, top } = element.getBoundingClientRect()
    const x = touch.clientX - Math.round(left)
    const y = touch.clientY - Math.round(top)
    return { x, y }
}

export const isMultiTouch = (event: React.TouchEvent): boolean => {
    return event.touches.length > 1
}

export const getPinchPoints = (
    event: React.TouchEvent,
    element: HTMLElement
): [ViewportPoint, ViewportCoordinate] => {
    const { left, top } = element.getBoundingClientRect()
    const x1 = event.touches[0].clientX - Math.round(left)
    const y1 = event.touches[0].clientY - Math.round(top)
    const x2 = event.touches[1].clientX - Math.round(left)
    const y2 = event.touches[1].clientY - Math.round(top)
    const pinchPointDistance = Math.sqrt(
        Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2)
    )
    const viewportPoint = { x: (x1 + x2) / 2, y: (y1 + y2) / 2 }
    return [viewportPoint, pinchPointDistance]
}
