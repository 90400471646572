import React from 'react'
import { FrontendChapterTileContent } from '../../../pre-build/static'
import ElementGeneric from './ElementGeneric'

interface Props {
    chapterTileContent: FrontendChapterTileContent
}

const ContentsChapter = ({ chapterTileContent }: Props) => {
    const elems = chapterTileContent.children.map((tileHtml, i) => (
        <ElementGeneric key={i} tileHtml={tileHtml} />
    ))
    return <div>{elems}</div>
}

export default ContentsChapter
