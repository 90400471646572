import React from 'react'
import styled from 'styled-components/macro'
import { BibliographyKey } from '../models'
import theme from '../theme'
import { useDispatch, useSelector } from 'react-redux'
import { addReferenceEntry } from '../store/referenceViewer'
import { RootState } from '../store'
import { selectReferenceViewerEntryIndex } from '../selectors'

interface OwnProps {
    bibliographyKey: BibliographyKey
}

type Props = OwnProps & React.HTMLAttributes<HTMLSpanElement>

const Button = styled.span`
    cursor: pointer;
    padding: 0 0.2em;
    background-color: ${theme.colors.tilePageBackground};
`

const IndexNumber = styled.span`
    text-decoration: underline;
`

const InlineReference = ({ bibliographyKey, ...props }: Props) => {
    const dispatch = useDispatch()
    const referenceViewerIndex = useSelector((state: RootState) =>
        selectReferenceViewerEntryIndex(state, bibliographyKey)
    )
    const onOpen = () => dispatch(addReferenceEntry(bibliographyKey))

    const element =
        referenceViewerIndex === null ? (
            <Button onClick={onOpen} className={props.className}>
                *
            </Button>
        ) : (
            <IndexNumber className={props.className}>
                {referenceViewerIndex}
            </IndexNumber>
        )

    return element
}

export default InlineReference
