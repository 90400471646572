import React from 'react'
import { MediaObjectType, TileHtmlElement } from '../../../pre-build/static'
import { useSelector } from 'react-redux'
import {
    selectMediaObjectFromId,
    selectBibliographyEntry,
} from '../../../selectors'
import { RootState } from '../../../store'
import styled from 'styled-components/macro'
import InlineReference from '../../InlineReference'

const MediaContainer = styled.div`
    position: relative;
`

const InlineReferenceCorner = styled(InlineReference)`
    position: absolute;
    top: 0;
    right: 0;
`

interface Props {
    attributes: TileHtmlElement['attributes']
}

const ObjectElement = ({ attributes }: Props) => {
    const mediaObjectId = attributes.data!
    const mediaObject = useSelector((state: RootState) =>
        selectMediaObjectFromId(state, mediaObjectId)
    )
    if (!mediaObject) {
        throw new Error(`unknown media object ${mediaObjectId}`)
    }

    const bibliographyEntry = useSelector((state: RootState) =>
        selectBibliographyEntry(state, mediaObject.id)
    )
    let inlineReferenceElem: JSX.Element | null = null
    if (bibliographyEntry) {
        inlineReferenceElem = (
            <InlineReferenceCorner bibliographyKey={bibliographyEntry.id} />
        )
    }
    if (mediaObject.mediaType === MediaObjectType.IMAGE) {
        return (
            <MediaContainer>
                <img src={mediaObject.urls[0]} alt={mediaObject.id} />
                {inlineReferenceElem}
            </MediaContainer>
        )
    } else if (mediaObject.mediaType === MediaObjectType.VIDEO) {
        return (
            <MediaContainer>
                <video controls autoPlay muted>
                    {/* <source src="/media/examples/flower.webm"
                            type="video/webm"> */}
                    <source src={mediaObject.urls[0]} type="video/mp4" />
                    Sorry, your browser doesn't support mp4 videos.
                </video>
                {inlineReferenceElem}
            </MediaContainer>
        )
    } else {
        throw new Error(`invalid ${mediaObject.mediaType}`)
    }
}

export default ObjectElement
