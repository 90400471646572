import React, { FunctionComponent } from 'react'
import {
    CONSTANTS,
    TileHtmlDiscriminants,
    TileHtmlElement,
    TileHtmlString,
} from '../../../pre-build/static'
import ElementCitationLink from './ElementCitationLink'

interface Props {
    tileHtml: TileHtmlElement | TileHtmlString
}

const ElementGeneric: FunctionComponent<Props> = ({ tileHtml }) => {
    switch (tileHtml.discriminant) {
        case TileHtmlDiscriminants.ELEMENT:
            if (
                Object.keys(tileHtml.attributes).includes(
                    CONSTANTS.BIBLIOGRAPHY_KEY
                )
            ) {
                return (
                    <ElementCitationLink
                        bibliographyKey={
                            tileHtml.attributes[CONSTANTS.BIBLIOGRAPHY_KEY]
                        }
                    />
                )
            } else {
                // We must make sure to not set children to elements tags that shouldnt have
                // any, otherwise react will not be happy.
                let childElements: Array<JSX.Element> | null = null
                if (tileHtml.children && tileHtml.children.length) {
                    childElements = tileHtml.children.map(
                        (childTileHtml, i) => (
                            <ElementGeneric key={i} tileHtml={childTileHtml} />
                        )
                    )
                }
                const TagName = tileHtml.type as keyof JSX.IntrinsicElements
                return <TagName>{childElements}</TagName>
            }
        case TileHtmlDiscriminants.STRING:
            return <React.Fragment>{tileHtml.html}</React.Fragment>
        default:
            console.error(tileHtml)
            throw new Error(`unknown TileHtml discriminant`)
    }
}

export default ElementGeneric
