import React from 'react'
import {
    TileHtmlString,
    TileHtmlElement,
    CONSTANTS,
} from '../../../pre-build/static'
import styled from 'styled-components/macro'
import InlineReference from '../../InlineReference'

const Container = styled.div`
    display: flex;
    flex-direction: row;
`

const Quote = styled.div`
    flex: 1;
    position: relative;
`

const QuoteSpan = styled.span`
    position: relative;
`

const BottomCornerInlineReference = styled(InlineReference)`
    position: absolute;
    bottom: 0;
    right: 0;
`

const OpenQuoteChar = styled.div`
    align-self: flex-start;
    font-size: 400%;
    ${QuoteSpan} {
        bottom: 0.2em;
    }
`

const CloseQuoteChar = styled(OpenQuoteChar)`
    align-self: flex-end;
    ${QuoteSpan} {
        top: 0.2em;
    }
`

interface Props {
    tileHtmlString: TileHtmlString
    attributes: TileHtmlElement['attributes']
}

const ElementBlockQuote = ({ tileHtmlString, attributes }: Props) => {
    const bibliographyKey = attributes[CONSTANTS.BLOCKQUOTE_BIBLIOGRAPHY_KEY]
    let referenceElem: JSX.Element | null = null
    if (bibliographyKey) {
        referenceElem = (
            <BottomCornerInlineReference bibliographyKey={bibliographyKey} />
        )
    }
    return (
        <Container>
            <OpenQuoteChar>
                <QuoteSpan>“</QuoteSpan>
            </OpenQuoteChar>
            <Quote>
                <blockquote
                    dangerouslySetInnerHTML={{ __html: tileHtmlString.html }}
                ></blockquote>
                {referenceElem}
            </Quote>
            <CloseQuoteChar>
                <QuoteSpan>„</QuoteSpan>
            </CloseQuoteChar>
        </Container>
    )
}

export default ElementBlockQuote
