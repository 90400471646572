import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { selectTileFromId } from '../../selectors'
import { TileType, TileId } from '../../models'
import ContentsNormalTile from './ContentsNormal'
import ContentsChapter from './ContentsChapter'
import styled from 'styled-components/macro'
import theme from '../../theme'
import {
    FrontendTileContent,
    FrontendChapterTileContent,
} from '../../pre-build/static'
import Tags from './Tags'
import { INTRO_TILE_ID, SITE_TITLE } from '../../constants'
import ErrorBoundary from './ErrorBoundary'
import ReferencerViewer from '../ReferenceViewer'

const borderMixin = `
    border: 3px solid ${theme.colors.textLight};
`

interface PageContentProps {
    hasTags: boolean
}

const PageContent = styled.div<PageContentProps>`
    flex: 1;
    overflow: auto;
    img,
    video {
        width: 100%;
    }

    ${(props) =>
        !props.hasTags
            ? `
    > *:first-child {
        margin-top: ${theme.spacing.space1}rem;
    }
    `
            : ''}

    section > *,
    main > *,
    p {
        margin-top: ${theme.spacing.space1}rem;
    }

    section > *:first-child,
    main > *:first-child,
    p:first-child {
        margin-top: 0;
    }

    section > *:last-child,
    main > *:last-child {
        margin-bottom: ${theme.spacing.space1}rem;
    }

    ul {
        list-style: disc;
        padding-left: 2em;
    }
`

const Title = styled.h1`
    ${borderMixin}
    display: flex;
    flex-direction: row;
    min-height: 3em;

    > * {
        align-self: center;
    }
`

const TitleText = styled.div`
    display: inline-block;
`

const BackButton = styled.button`
    align-self: center;
    background: none;
    border: none;
    font-size: 150%;
    padding: 0 0.5em;
`

const StyledReferencerViewer = styled(ReferencerViewer)`
    ${borderMixin}
`

interface Props {
    tileId: TileId
    tileContent: FrontendTileContent | FrontendChapterTileContent
    onBackClicked: (event: React.SyntheticEvent) => void
}

const ContentsGeneric = ({ tileId, onBackClicked, tileContent }: Props) => {
    const tile = useSelector((state: RootState) =>
        selectTileFromId(state, tileId)
    )
    if (!tile) {
        throw new Error(`unknown tile id ${tileId}`)
    }
    const hasTags = !!tile.tags.length

    let contentsElem: JSX.Element
    if (tile.type === TileType.tile) {
        contentsElem = (
            <ContentsNormalTile
                tileContent={tileContent as FrontendTileContent}
            />
        )
    } else if (tile.type === TileType.chapter) {
        if (tile.id === INTRO_TILE_ID) {
            tileContent.title = SITE_TITLE
        }
        contentsElem = (
            <ContentsChapter
                chapterTileContent={tileContent as FrontendChapterTileContent}
            />
        )
    } else {
        throw new Error(`invalid tile type ${tile.type}`)
    }

    return (
        <React.Fragment>
            <Title>
                <BackButton onClick={onBackClicked}>{'<'}</BackButton>
                <TitleText>{tileContent.title}</TitleText>
            </Title>
            {hasTags ? <Tags tags={tile.tags} /> : null}
            <PageContent hasTags={hasTags}>
                <ErrorBoundary tileId={tile.id}>{contentsElem}</ErrorBoundary>
            </PageContent>
            <StyledReferencerViewer />
        </React.Fragment>
    )
}

export default ContentsGeneric
