import React from 'react'
import deepEqual from 'lodash.isequal'
import { TILE_SIZE } from '../../constants'
import { HexPoint } from '../../pre-build/static'
import { calculatePointFromHexPoint, randInt, randNumber } from '../../maths'
import { HexagonClippedGroup } from './shared'
import HexagonContainer from './HexagonContainer'
import styled, { css, keyframes } from 'styled-components/macro'
import sample from 'lodash.sample'
import range from 'lodash.range'

const COLOR_ELEMENT_CHOICE = '123'
const COLOR_ELEMENT_BASE = '0'
const ANIM_DURATION: [number, number] = [50, 200]
const ANIM_STEPS: [number, number] = [5, 10]
const ALTERNATIVE_COUNT = 10

const makeColorKeyframes = () => {
    const stepsCount = randInt(...ANIM_STEPS)
    const steps = []
    for (let i = 0; i < stepsCount; i++) {
        let color =
            '#' +
                sample(COLOR_ELEMENT_CHOICE) +
                COLOR_ELEMENT_BASE +
                sample(COLOR_ELEMENT_CHOICE)
        steps.push([Math.floor((i / stepsCount) * 100), color])
    }
    return keyframes`
        ${steps
            .map(
                ([percentage, color]) =>
                    `${percentage}%{background-color:${color}}`
            )
            .join('\n')}
    `
}

// Pre-generate a bunch of random styled components, because if we create these dynamically
// a new CSS class will be created every time.
const AnimatedColor = styled(HexagonClippedGroup)`
    width: 100%;
    height: 100%;
    ${range(ALTERNATIVE_COUNT).map((i) => {
        const colorKeyframes = makeColorKeyframes()
        return css`
            &.alternative${i} {
                animation: ${colorKeyframes} ${randNumber(...ANIM_DURATION)}ms
                    ease infinite;
            }
        `
    })}
`

interface Props {
    hexPoint: HexPoint
}

const TileEmpty = ({ hexPoint }: Props) => {
    const point = calculatePointFromHexPoint(hexPoint, TILE_SIZE)
    const alternativeClassName = `alternative${randInt(0, ALTERNATIVE_COUNT)}`
    return (
        <HexagonContainer position={point}>
            <AnimatedColor className={alternativeClassName} />
        </HexagonContainer>
    )
}

// Deep equal because we want to avoid re-render when receiving hex points that are the
// same points, but different instances.
export default React.memo(TileEmpty, deepEqual)
