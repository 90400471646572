import { all, takeEvery } from 'redux-saga/effects'
import { setHtmlVideoMuted } from '../utils'
import { CLICKED_TILE_SET_SOUND_ON, SetSoundOn } from './clickedTile'

function* muteUnmuteVideoTask(action: SetSoundOn) {
    if (action.payload.previousTileId) {
        setHtmlVideoMuted(action.payload.previousTileId, true)
    }
    if (action.payload.tileId) {
        setHtmlVideoMuted(action.payload.tileId, false)
    }
    yield
}

function* muteUnmuteVideoSaga() {
    yield takeEvery(CLICKED_TILE_SET_SOUND_ON, muteUnmuteVideoTask)
}

function* rootSaga() {
    yield all([muteUnmuteVideoSaga()])
}

export default rootSaga
