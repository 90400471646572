import preloadedBibliographyData from '../pre-build/bibliography.json'
import { BibliographyEntry } from '../models'

export const preloadedBibliography = (): BibliographyState => {
    const bibliography: BibliographyState = {}
    Object.entries(preloadedBibliographyData).forEach(([key, value]) => {
        bibliography[key] = { citation: value.f, id: key }
    })
    return bibliography
}

export type BibliographyState = {
    [bibliographyKey: string]: BibliographyEntry | undefined
}

const initialState: BibliographyState = {}

export function bibliographyReducer(
    state = initialState,
    action: { type: string }
): BibliographyState {
    switch (action.type) {
        default:
            return state
    }
}
