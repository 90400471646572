import React from 'react'
import { TileHtmlElement } from '../../../pre-build/static'
import ElementGeneric from './ElementGeneric'
import styled from 'styled-components/macro'
import theme from '../../../theme'

const MainStyled = styled.main`
    margin-bottom: ${theme.spacing.space1}rem;
`

interface Props {
    tileHtmlElement: TileHtmlElement
}

const ElementMain = ({ tileHtmlElement }: Props) => {
    const elems = tileHtmlElement.children!.map((child, i) => (
        <ElementGeneric tileHtmlElement={child} key={i} />
    ))

    return <MainStyled>{elems}</MainStyled>
}

export default ElementMain
