export const TILE_SIZE = 300

export const PAGE_ZOOM_DURATION = 1

export const TILE_PAGE_PORTAL_ID = 'tile-page'
export const TILES_CONTENT_ROOT_URL = `/tiles`
export const THUMBNAILS_ROOT_URL = `/thumbnails`
export const SITE_TITLE = 'HYPEREXISTENCE'
export const GOOGLE_ANALYTICS_ID = 'UA-17637518-6'

export const INTRO_TILE_ID = 'Introduction'

export const DEBUG = false

export const HEXAGON_FORMULA: Array<[number, number]> = [
    [0.5, 0],
    [1, 0.25],
    [1, 0.75],
    [0.5, 1],
    [0, 0.75],
    [0, 0.25],
]
