import React from 'react'
import {
    TileHtmlElement,
    TileHtmlString,
    TileHtmlDiscriminants,
    CONSTANTS,
} from '../../../pre-build/static'
import ElementBlockQuote from './ElementBlockQuote'
import TilePageSection from './ElementSection'
import ElementDiv from './ElementDiv'
import ElementMain from './ElementMain'
import ObjectElement from './ElementObject'

interface PropsNested {
    tileHtmlElement: TileHtmlElement
}

const ElementGenericContainer = ({ tileHtmlElement }: PropsNested) => {
    switch (tileHtmlElement.type) {
        case 'section':
            return <TilePageSection tileHtmlElement={tileHtmlElement} />
        case 'main':
            return <ElementMain tileHtmlElement={tileHtmlElement} />
        default:
            throw new Error(`invalid tile html type ${tileHtmlElement.type}`)
    }
}

const ElementGenericEnd = ({ tileHtmlElement }: PropsNested) => {
    const tileHtmlString = tileHtmlElement.children[0] as TileHtmlString
    switch (tileHtmlElement.type) {
        case 'blockquote':
            return (
                <ElementBlockQuote
                    tileHtmlString={tileHtmlString}
                    attributes={tileHtmlElement.attributes}
                />
            )
        case 'div':
            return <ElementDiv tileHtmlString={tileHtmlString} />
        case 'object':
            return <ObjectElement attributes={tileHtmlElement.attributes} />
        default:
            throw new Error(`invalid tile html type ${tileHtmlElement.type}`)
    }
}

interface Props {
    // Can only really be a TileHtmlElement, otherwise we throw and error
    tileHtmlElement: TileHtmlElement | TileHtmlString
}

const ElementGeneric = ({ tileHtmlElement }: Props) => {
    if (tileHtmlElement.discriminant === TileHtmlDiscriminants.ELEMENT) {
        if (
            CONSTANTS.NORMAL_TILE_CONTENT_CONTAINER_TAGS.includes(
                tileHtmlElement.type
            )
        ) {
            return <ElementGenericContainer tileHtmlElement={tileHtmlElement} />
        } else {
            return <ElementGenericEnd tileHtmlElement={tileHtmlElement} />
        }
    } else {
        throw new Error(`invalid tile html string`)
    }
}

export default ElementGeneric
