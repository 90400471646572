import React from 'react'
import { FrontendTileContent } from '../../../pre-build/static'
import ElementGeneric from './ElementGeneric'

interface Props {
    tileContent: FrontendTileContent
}

const ContentsNormalTile = ({ tileContent }: Props) => {
    const elems = tileContent.children.map((tileHtmlElement, i) => (
        <ElementGeneric tileHtmlElement={tileHtmlElement} key={i} />
    ))
    return <div>{elems}</div>
}

export default ContentsNormalTile
