import preloadedMediaObjectData from '../pre-build/media_objects.json'
import preloadedServerData from '../pre-build/media_servers.json'
import { FrontendMediaObject } from '../models'

export const preloadedMediaObjects = (): MediaObjectState => {
    const mediaObjectState: MediaObjectState = {}
    preloadedMediaObjectData.forEach((m: any) => {
        const serverUrl = preloadedServerData[m.server_id].url
        mediaObjectState[m.id] = {
            id: m.id,
            mediaType: m.media_type,
            urls: m.filenames.map(
                (filename: string) => `${serverUrl}/${filename}`
            ),
        }
    })
    return mediaObjectState
}

export type MediaObjectState = {
    [mediaObjectId: string]: FrontendMediaObject | undefined
}

const initialState: MediaObjectState = {}

export function mediaObjectsReducer(
    state = initialState,
    action: { type: string }
): MediaObjectState {
    switch (action.type) {
        default:
            return state
    }
}
