import React from 'react'
import { TileHtmlElement } from '../../../pre-build/static'
import ElementGeneric from './ElementGeneric'

interface Props {
    tileHtmlElement: TileHtmlElement
}

const TilePageSection = ({ tileHtmlElement }: Props) => {
    const elems = tileHtmlElement.children!.map((tileHtmlElement, i) => (
        <ElementGeneric tileHtmlElement={tileHtmlElement} key={i} />
    ))

    return <section>{elems}</section>
}

export default TilePageSection
