import styled from 'styled-components/macro'
import { clickedTileStart, clickedTileEnd } from '../../store/clickedTile'
import { TileId } from '../../models'
import theme from '../../theme'
import { useDispatch } from 'react-redux'
import { isTouchDevice } from '../../utils'
import React from 'react'

export const HexagonClippedGroup = styled.div`
    /* this is the child container cut as HEX, therefore
    this needs to respond to clicks */
    pointer-events: all;
    ${theme.mixins.polygonClipPath}
    height: 100%;
    width: 100%;
`

interface TileClickHandlers {
    onMouseDown?: (event: React.MouseEvent) => void
    onTouchStart?: (event: React.TouchEvent) => void
    onMouseUp?: (event: React.MouseEvent) => void
    onTouchEnd?: (event: React.TouchEvent) => void
}

export const useTileClick = (tileId: TileId) => {
    const dispatch = useDispatch()
    const handlers: TileClickHandlers = {}
    const _onClickOrTouchStart = () => dispatch(clickedTileStart(tileId))

    if (isTouchDevice()) {
        handlers.onTouchStart = _onClickOrTouchStart
        handlers.onTouchEnd = (event: React.TouchEvent) => {
            // In the case there is no tile controls visible, and the touch end
            // occurs right where a button is about to appear, the bouton will be
            // clicked in the same event handling cycle, which is not what we want.
            // Instead we just want the controls to appear and that's all, therefore
            // we block the button click by using `preventDefault`.
            event.preventDefault()
            dispatch(clickedTileEnd(tileId))
        }
    } else {
        handlers.onMouseDown = _onClickOrTouchStart
        handlers.onMouseUp = () => {
            dispatch(clickedTileEnd(tileId))
        }
    }

    return handlers
}
