import React from 'react'
import { useSelector } from 'react-redux'
import { INTRO_TILE_ID } from '../../constants'
import {
    memoizedSelectBackgroundHexPoints,
    memoizedSelectTilesInView,
} from '../../selectors'
import TileHexagon from '../TileHexagon'
import TileEmpty from '../TileHexagon/TileEmpty'
import TileIntro from '../TileHexagon/TileIntro'

const HiveTiles = () => {
    const tilesInView = useSelector(memoizedSelectTilesInView)
    const backgroundHexPoints = useSelector(memoizedSelectBackgroundHexPoints)
    let introElem: JSX.Element | null = null
    const tileElems: Array<JSX.Element> = []
    tilesInView.forEach((tile) => {
        if (tile.id === INTRO_TILE_ID) {
            introElem = <TileIntro tile={tile} />
        } else {
            tileElems.push(<TileHexagon tile={tile} key={tile.id} />)
        }
    })
    const tileEmptyElems = backgroundHexPoints.map((hexPoint) => (
        <TileEmpty
            hexPoint={hexPoint}
            key={`${hexPoint.hex_x},${hexPoint.hex_y}`}
        />
    ))

    return (
        <>
            {introElem}
            {tileElems}
            {tileEmptyElems}
        </>
    )
}

export default React.memo(HiveTiles)
