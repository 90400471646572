import React from 'react'
import { FrontendTile } from '../../models'
import styled from 'styled-components/macro'
import { HexagonClippedGroup, useTileClick } from './shared'

const Container = styled.div`
    width: 100%;
    height: 100%;
`

const ClickArea = styled(HexagonClippedGroup)`
    width: 100%;
    height: 100%;
    background-color: black;
`

interface Props {
    tile: FrontendTile
    isFocused: boolean
}

const TileHexagonChapter = ({ tile }: Props) => {
    const tileClickHandlers = useTileClick(tile.id)
    return (
        <Container>
            <ClickArea {...tileClickHandlers}></ClickArea>
        </Container>
    )
}

export default TileHexagonChapter
