import React, { ErrorInfo } from 'react'
import ReactGA from 'react-ga'

interface Props {
    tileId: string
}

interface State {
    hasError: boolean
}

class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true }
    }

    componentDidCatch(error: Error, info: ErrorInfo) {
        ReactGA.exception({
            description: `TILE ${this.props.tileId} error rendering HTML contents`,
            fatal: false,
        })
    }

    render() {
        if (this.state.hasError) {
            return (
                <h1>☢ Something went wrong : content cannot be rendered ☢</h1>
            )
        }
        return this.props.children
    }
}

export default ErrorBoundary
