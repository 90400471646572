import React, { useRef, useEffect, useState } from 'react'
import round from 'lodash.round'
import styled from 'styled-components'
import { setSize } from '../store/svgTextSize'
import { useDispatch } from 'react-redux'
import theme from '../theme'

const TEXT1 = 'TEST text'
const TEXT2 = 'size'
const DIMENSIONS = [100, 100]

const Svg = styled.svg`
    width: ${DIMENSIONS[0]}px;
    height: ${DIMENSIONS[1]}px;
    position: absolute;
    top: 0;
    left: 0;
`

const TransparentText = styled.text`
    opacity: 0;
    font-size: ${theme.fontSizes.basePx}px;
`

const SvgTextSizeMeasurement = () => {
    const textRef = useRef<SVGTextElement>(null)
    const tspanRef1 = useRef<SVGTSpanElement>(null)
    const tspanRef2 = useRef<SVGTSpanElement>(null)
    const dispatch = useDispatch()
    const [measurementDone, setMeasurementDone] = useState(false)

    useEffect(() => {
        if (!measurementDone && tspanRef1.current && tspanRef2.current) {
            const doMeasurements = () => {
                const textBbox = textRef.current!.getBBox()
                const tspan1Bbox = tspanRef1.current!.getBBox()
                const tspan2Bbox = tspanRef2.current!.getBBox()
                const charWidth = round(textBbox.width / TEXT1.length, 1)
                const lineHeight = round(
                    (tspan1Bbox.height + tspan2Bbox.height) / 2,
                    1
                )
                setMeasurementDone(true)
                dispatch(setSize(charWidth, lineHeight))
            }
            doMeasurements()

            // This is to fix dimensions measurements on safari.
            setTimeout(doMeasurements, 1000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Width measurement on Safari is confusing with the tspans, returned width of tspan is width of whole text element
    // Therefore, we measure with a separate text element for width
    return (
        <Svg viewBox={`0 0 ${DIMENSIONS[0]} ${DIMENSIONS[1]}`}>
            <TransparentText ref={textRef}>{TEXT1}</TransparentText>
            <TransparentText>
                <tspan ref={tspanRef1} x={-100}>
                    {TEXT1}
                </tspan>
                <tspan ref={tspanRef2} x={-100}>
                    {TEXT2}
                </tspan>
            </TransparentText>
        </Svg>
    )
}

export default React.memo(SvgTextSizeMeasurement)
