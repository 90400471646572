import React from 'react'
import { FrontendTile, TileType } from '../../models'
import { useSelector } from 'react-redux'
import TilePagePortal from '../TilePagePortal'
import TilePage from '../TilePage'
import {
    selectFocusedTileId,
    selectSoundTileId,
    selectZoomedTileId,
} from '../../selectors'
import TileHexagonNormal from './TileHexagonNormal'
import TileHexagonChapter from './TileHexagonChapter'
import HexagonContainer from './HexagonContainer'
import Overlay, { MODE } from './Overlay/Overlay'
import { BUTTON_TYPE } from './Overlay/Buttons'
import { MediaObjectType } from '../../pre-build/static'

interface Props {
    tile: FrontendTile
}

const TileHexagon = ({ tile }: Props) => {
    const tileId = tile.id
    const isZoomed = useSelector(selectZoomedTileId) === tileId
    const isFocused = useSelector(selectFocusedTileId) === tileId
    const isPlaying = useSelector(selectSoundTileId) === tileId

    let hexagonContentElem: JSX.Element | null = null
    const buttonsList: Array<BUTTON_TYPE> = ['READ_MORE']
    let overlayMode: MODE
    let hasOverlay: boolean = true
    if (tile.type === TileType.tile) {
        hasOverlay = isPlaying || isFocused
        overlayMode = isFocused ? 'FULL' : 'HALO'
        hexagonContentElem = (
            <TileHexagonNormal tile={tile} isFocused={isFocused} />
        )
        if (tile.thumbnailType === MediaObjectType.VIDEO) {
            buttonsList.push('SOUND_ON_OFF')
        }
    } else if (tile.type === TileType.chapter) {
        hasOverlay = true
        overlayMode = isFocused ? 'FULL' : 'CHAPTER'
        hexagonContentElem = (
            <TileHexagonChapter tile={tile} isFocused={isFocused} />
        )
    } else {
        throw new Error(`invalid tile type ${tile.type}`)
    }

    return (
        <HexagonContainer position={tile.position}>
            {hasOverlay ? (
                <Overlay
                    tile={tile}
                    isShowingButtons={true}
                    buttons={buttonsList}
                    mode={overlayMode}
                />
            ) : null}
            {hexagonContentElem}
            {isZoomed ? (
                <TilePagePortal>
                    <TilePage tileId={tileId} />
                </TilePagePortal>
            ) : null}
        </HexagonContainer>
    )
}

export default React.memo(TileHexagon)
