import { combineReducers, createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { panZoomReducer } from './panZoom'
import rootSaga from './sagas'
import { tilesReducer, preloadedTiles } from './tiles'
import { svgTextSizeReducer } from './svgTextSize'
import { zoomedTileReducer } from './zoomedTile'
import { preloadedMediaObjects, mediaObjectsReducer } from './mediaObjects'
import { clickedTileReducer } from './clickedTile'
import { windowDimensionsReducer } from './windowDimensions'
import { preloadedBibliography, bibliographyReducer } from './bibliography'
import { referenceViewerReducer } from './referenceViewer'

const rootReducer = combineReducers({
    panZoom: panZoomReducer,
    tiles: tilesReducer,
    mediaObjects: mediaObjectsReducer,
    zoomedTile: zoomedTileReducer,
    clickedTile: clickedTileReducer,
    svgTextSize: svgTextSizeReducer,
    windowDimensions: windowDimensionsReducer,
    bibliography: bibliographyReducer,
    referenceViewer: referenceViewerReducer,
})

export type RootState = ReturnType<typeof rootReducer>

const sagaMiddleware = createSagaMiddleware()
const enhancers = [applyMiddleware(sagaMiddleware)]

if (
    process.env.NODE_ENV === 'development' &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__
) {
    enhancers.push((window as any).__REDUX_DEVTOOLS_EXTENSION__())
}

const preloadedState = {
    tiles: preloadedTiles(),
    mediaObjects: preloadedMediaObjects(),
    bibliography: preloadedBibliography(),
}

export const store = createStore(
    rootReducer,
    preloadedState,
    compose(...enhancers)
)

sagaMiddleware.run(rootSaga)
