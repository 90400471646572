import uniq from 'lodash.uniq'
import without from 'lodash.without'
import { BibliographyKey } from '../models'
import { ZOOMED_TILE_UNSET_ZOOMED, ZoomedTileActionTypes } from './zoomedTile'

export const REFERENCE_VIEWER_ADD_ENTRY = 'REFERENCE_VIEWER_ADD_ENTRY'
export const REFERENCE_VIEWER_REMOVE_ENTRY = 'REFERENCE_VIEWER_REMOVE_ENTRY'

interface AddEntry {
    type: typeof REFERENCE_VIEWER_ADD_ENTRY
    payload: {
        bibliographyKey: BibliographyKey
    }
}

interface RemoveEntry {
    type: typeof REFERENCE_VIEWER_REMOVE_ENTRY
    payload: {
        bibliographyKey: BibliographyKey
    }
}

export type ReferenceViewerActionTypes = AddEntry | RemoveEntry

export const addReferenceEntry = (
    bibliographyKey: BibliographyKey
): ReferenceViewerActionTypes => {
    return {
        type: REFERENCE_VIEWER_ADD_ENTRY,
        payload: { bibliographyKey },
    }
}

export const removeReferenceEntry = (
    bibliographyKey: BibliographyKey
): ReferenceViewerActionTypes => {
    return {
        type: REFERENCE_VIEWER_REMOVE_ENTRY,
        payload: { bibliographyKey },
    }
}

export type ReferenceViewerState = Array<BibliographyKey>

const initialState: ReferenceViewerState = []

export function referenceViewerReducer(
    state = initialState,
    action: ReferenceViewerActionTypes | ZoomedTileActionTypes
): ReferenceViewerState {
    // We want to avoid clicks on drag actions, so we
    // memorize the start of a click, and cancel the click
    // if the user dragged before we receive click end.
    switch (action.type) {
        case REFERENCE_VIEWER_ADD_ENTRY:
            return uniq([...state, action.payload.bibliographyKey])
        case REFERENCE_VIEWER_REMOVE_ENTRY:
            return without(state, action.payload.bibliographyKey)
        case ZOOMED_TILE_UNSET_ZOOMED:
            return initialState
        default:
            return state
    }
}
