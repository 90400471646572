export enum TileTypes {
    CHAPTER = 'c',
    TILE = 't',
}

export enum MediaObjectType {
    IMAGE = 'image',
    VIDEO = 'video',
}

export interface HexPoint {
    hex_x: number
    hex_y: number
}

export interface FrontendTile {
    id: string
    title: string
    type: TileTypes
    hex: HexPoint
    tags: Array<string>
}

export interface FrontendMediaObject {
    id: string
    media_type: MediaObjectType
    filenames: Array<string>
    server_id: number
}

export interface TileHtmlElement {
    type: string
    attributes: { [key: string]: string }
    children: Array<TileHtmlElement | TileHtmlString>
    discriminant: TileHtmlDiscriminants.ELEMENT
}

export interface TileHtmlString {
    html: string
    discriminant: TileHtmlDiscriminants.STRING
}

export enum TileHtmlDiscriminants {
    STRING = 'string',
    ELEMENT = 'element',
}

export interface FrontendTileContent {
    id: number
    children: Array<TileHtmlElement | TileHtmlString>
    title: string
    tags: Array<string>
}

export interface FrontendChapterTileContent {
    id: string
    children: Array<TileHtmlElement | TileHtmlString>
    title: string
}

export interface FrontendBibliographyEntry {
    f: string
}

export const CONSTANTS = {
    BIBLIOGRAPHY_KEY: 'bibliography_key',
    BLOCKQUOTE_BIBLIOGRAPHY_KEY: 'cite',
    NORMAL_TILE_CONTENT_CONTAINER_TAGS: ['section', 'main'],
    NORMAL_TILE_CONTENT_END_TAGS: ['blockquote', 'div', 'object'],
    THUMBNAIL_FILENAME_TEMPLATE: 'TILE_ID.EXTENSION',
    THUMBNAIL_IMAGE_EXTENSION: 'jpg',
    THUMBNAIL_VIDEO_EXTENSIONS: ['mp4'],
}
