import React from 'react'
import { useRequestZoomToTile } from '../../../hooks'
import { ReadMoreIcon } from '../../icons'
import ButtonContainer, { ButtonProps, IconGroup } from './ButtonContainer'

const MoreButton: React.FunctionComponent<ButtonProps> = ({
    tileId,
    ...passThroughProps
}) => {
    const [zoomToTileEffect] = useRequestZoomToTile(tileId)

    return (
        <ButtonContainer onClick={zoomToTileEffect} {...passThroughProps}>
            <IconGroup>
                <ReadMoreIcon />
            </IconGroup>
        </ButtonContainer>
    )
}

export default MoreButton
