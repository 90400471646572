import { ViewportCoordinate } from '../types'

export const WHEEL = 'WHEEL'

interface WheelAction {
    type: typeof WHEEL
    payload: {
        x: ViewportCoordinate
        y: ViewportCoordinate
        scaleFactor: number
    }
}

export type WheelActionTypes = WheelAction

export const wheelAction = (
    x: ViewportCoordinate,
    y: ViewportCoordinate,
    scaleFactor: number
): WheelActionTypes => {
    return {
        type: WHEEL,
        payload: { x, y, scaleFactor },
    }
}
