import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { selectSvgTextSize } from '../../../selectors'
import theme from '../../../theme'
import { splitTextInLines } from '../../../utils'

const Text = styled.text`
    text-anchor: middle;
    font-size: ${theme.fontSizes.basePx}px;
`

interface Props {
    text: string
    containerWidth: number
    containerHeight: number
    centerY: number
    lineHeightRatio?: number
    xPadding?: number
}

const SvgTextWrapped = ({
    text,
    containerWidth,
    containerHeight,
    centerY,
    xPadding = 0,
    lineHeightRatio = 1,
    ...props
}: Props) => {
    const { charWidth, lineHeight } = useSelector(selectSvgTextSize)
    const lines = splitTextInLines(
        text,
        charWidth,
        containerWidth - xPadding * 2
    )
    const finalLineHeight = lineHeight * lineHeightRatio
    const tspans = lines.map((line) => (
        <tspan x={containerWidth / 2} dy={finalLineHeight} key={line}>
            {line}
        </tspan>
    ))

    // Layout rules are the following :
    // - Text is layed-out centered horizontally and vertically within the container
    // - If the text would overflow at the bottom of the container, we move it up by `yOffset` to avoid that
    const halfHeight = (lines.length * finalLineHeight) / 2 + lineHeight / 2
    // This allows to add an offset when we detect that text would overflow the container's height
    const yOffset = Math.min(containerHeight - (centerY + halfHeight), 0)
    const textY = centerY - halfHeight + yOffset

    if (textY < 0) {
        console.error(`text not fitting in height`)
    }

    return (
        <Text y={textY} {...props}>
            {tspans}
        </Text>
    )
}

export default React.memo(SvgTextWrapped)
