import React, { FunctionComponent } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { DEBUG } from '../constants'
import { convertViewportCoordsToUniverse } from '../panZoom/utils/math'
import {
    selectWindowDimensions,
    selectTransformMatrix,
    memoizedSelectViewport,
} from '../selectors'

const POINT_RADIUS_PX = 10

const Container = styled.div``

interface PointCoordinatesProps {
    x: number
    y: number
}

const PointCoordinates: FunctionComponent<PointCoordinatesProps> = ({
    x,
    y,
}) => {
    return (
        <span>
            ({Math.round(x)}, {Math.round(y)})
        </span>
    )
}

const PointContainer = styled.div<{ x: number; y: number; color?: string }>`
    z-index: 100;
    border-radius: ${POINT_RADIUS_PX}px;
    background-color: ${(props) => props.color};
    position: absolute;
    width: ${POINT_RADIUS_PX * 2}px;
    height: ${POINT_RADIUS_PX * 2}px;
    left: ${(props) => props.x - POINT_RADIUS_PX}px;
    top: ${(props) => props.y - POINT_RADIUS_PX}px;
`

const PointLabel = styled.div`
    position: absolute;
    width: 200px;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.3);
`

interface PointViewProps {
    x: number
    y: number
    label?: string
    color?: string
}

const PointView: FunctionComponent<PointViewProps> = ({
    x,
    y,
    label = '',
    color = 'red',
}) => {
    return (
        <PointContainer x={x} y={y} color={color}>
            <PointLabel>
                <PointCoordinates x={x} y={y} />
                <br />
                <span>{label}</span>
            </PointLabel>
        </PointContainer>
    )
}

interface ViewportViewProps {}

const ViewportView: FunctionComponent<ViewportViewProps> = () => {
    const { topLeft, bottomRight } = useSelector(memoizedSelectViewport)
    return (
        <>
            <PointView
                x={topLeft.x}
                y={topLeft.y}
                label="VIEWPORT - TOP LEFT"
                color="green"
            />
            <PointView
                x={bottomRight.x}
                y={bottomRight.y}
                label="VIEWPORT - BOTTOM RIGHT"
                color="green"
            />
        </>
    )
}

// Component for testing some maths calculations
const TestMaths = () => {
    const transformMatrix = useSelector(selectTransformMatrix)
    const { width, height } = useSelector(selectWindowDimensions)
    if (!DEBUG) {
        return null
    }
    const universePoint = convertViewportCoordsToUniverse(
        transformMatrix,
        width / 2,
        height / 2
    )
    return (
        <Container>
            <PointView
                x={universePoint.x}
                y={universePoint.y}
                label="TESTING : conversion {viewport coordinates} -> {universe coordinates} EXPECTED : should be at the center of page"
            />
            <ViewportView />
        </Container>
    )
}

export default TestMaths
