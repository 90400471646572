import { HEXAGON_FORMULA } from './constants'
import { DeviceScaledSettings } from './models'
import { makeRgbaColor } from './utils'

const GLITCH_RED: [number, number, number] = [255, 0, 0]
const GLITCH_BLUE: [number, number, number] = [0, 0, 255]

const colors = {
    tilePageBackground: 'rgba(0, 79, 79, 0.7)',
    tileHexagonBackground: 'black',
    tagsBackground: 'rgba(255, 255, 255, 0.3)',
    textLight: 'white',
    textDark: 'black',
    textLinkLight: 'rgba(0, 216, 216, 1)',
    textMainTitle: '#ddd',
    thumbBackground: 'rgba(255, 255, 255, 0.5)',
    scrollBackground: 'transparent',
    glitchRed: makeRgbaColor(GLITCH_RED, 1),
    glitchBlue: makeRgbaColor(GLITCH_BLUE, 1),
    glitchRedOpacity: makeRgbaColor(GLITCH_RED, 0.2),
    glitchBlueOpacity: makeRgbaColor(GLITCH_BLUE, 0.4),
    titleWhite: 'white',
    titleGrey: '#111',
}

const tileOverlay = {
    buttons: {
        containerHeight: 1 / 3,
        paddingTop: 15,
    },
    hexagons: {
        strokeWidth: 3,
        // Amount of pixels the hexagons move in glitch animation
        displace: 1,
        blurRadius: 1,
    },
    title: {
        xPadding: 5,
    },
    titleAndIcons: {
        strokeWidth: 2,
    },
}

const scrollbarMixin = `
    * {
        scrollbar-width: thin;
        scrollbar-color: ${colors.thumbBackground} ${colors.scrollBackground};
    }

    * ::-webkit-scrollbar {
        width: 11px;
    }
    * ::-webkit-scrollbar-track {
        background: ${colors.scrollBackground};
    }
    * ::-webkit-scrollbar-thumb {
        background-color: ${colors.thumbBackground} ;
        border-radius: 6px;
        border: 3px solid ${colors.scrollBackground};
    }
`

const theme = {
    fontSizes: {
        basePx: 20,
    },
    colors,
    tileOverlay,
    // units in rem
    spacing: {
        space1: 1,
    },
    mixins: {
        unselectable: `
            user-select: none;
        `,
        polygonClipPath: `clip-path: polygon(${HEXAGON_FORMULA.map(
            ([xRatio, yRatio]) => `${xRatio * 100}% ${yRatio * 100}%`
        ).join(', ')});`,
        scrollbar: scrollbarMixin,
    },
    zIndex: {
        tileIntro: 8,
    },
    // For docs of values look at the interface definition
    deviceScaledSettings: {
        width: [375, 1800],
        // left value corresponds to small width, right value to large width
        maxScale: [5, 5],
        minScale: [0.8, 1.4],
        initialScale: [0.8, 1.4],
        initialYOffsetRatio: [0.06, 0.11],
        zoomToPageScaleThreshold: [10, 10],
        zoomToPageScale: [50, 50],
        zoomToPageScaleBack: [1.05, 2],
        titleFontSizeRatio: [1.65, 3],
    } as DeviceScaledSettings,
}

export default theme as Readonly<typeof theme>
