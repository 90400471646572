import { TileId } from '../models'
import { PAN_ZOOM_SET_INTERNAL, PanZoomStateTypes } from './panZoom'

export const CLICKED_TILE_START = 'CLICKED_TILE_START'
export const CLICKED_TILE_END = 'CLICKED_TILE_END'
export const CLICKED_TILE_SET_SOUND_ON = 'CLICKED_TILE_SET_SOUND_ON'

interface ClickStart {
    type: typeof CLICKED_TILE_START
    payload: {
        tileId: TileId
    }
}

interface ClickEnd {
    type: typeof CLICKED_TILE_END
    payload: {
        tileId: TileId
    }
}

export interface SetSoundOn {
    type: typeof CLICKED_TILE_SET_SOUND_ON
    payload: {
        tileId: TileId | null
        // Needed for the side-effect (for muting previous video)
        previousTileId: TileId | null
    }
}

export type ClickedTileActionTypes = ClickStart | ClickEnd | SetSoundOn

export const clickedTileStart = (tileId: TileId): ClickedTileActionTypes => {
    return {
        type: CLICKED_TILE_START,
        payload: { tileId },
    }
}

export const clickedTileEnd = (tileId: TileId): ClickedTileActionTypes => {
    return {
        type: CLICKED_TILE_END,
        payload: { tileId },
    }
}

export const clickedTileSetSoundOn = (
    tileId: TileId | null,
    previousTileId: TileId | null
): ClickedTileActionTypes => {
    return {
        type: CLICKED_TILE_SET_SOUND_ON,
        payload: { tileId, previousTileId },
    }
}

export interface ClickedTileState {
    clicked: TileId | null
    focused: TileId | null
    sound: TileId | null
    dragged: boolean
}

const initialState: ClickedTileState = {
    clicked: null,
    focused: null,
    sound: null,
    dragged: false,
}

export function clickedTileReducer(
    state = initialState,
    action: ClickedTileActionTypes | PanZoomStateTypes
): ClickedTileState {
    switch (action.type) {
        // We want to avoid clicks on drag actions, so we
        // memorize the start of a click, and cancel the click
        // if the user dragged before we receive click end.
        case CLICKED_TILE_START:
            return { ...state, clicked: action.payload.tileId, dragged: false }

        case PAN_ZOOM_SET_INTERNAL:
            // Cancel the click only if transform changed, meaning that a drag/zoom occurred.
            if (action.payload.transformMatrixChanged) {
                return {
                    ...state,
                    clicked: null,
                    dragged: true,
                    // Animations make the app too slow.
                    // So when panning, we remove focus to hide animations
                    focused: null,
                }
            } else {
                return state
            }

        case CLICKED_TILE_END:
            return {
                ...state,
                clicked: null,
                dragged: false,
                focused:
                    // If a drag has occurred, we don't change the state of focus
                    state.dragged === false
                        ? state.clicked &&
                          // If the end of the click occurs on the same tile that was already being clicked
                          state.clicked === action.payload.tileId &&
                          // If that tile is already focused, we unfocus
                          state.focused !== action.payload.tileId
                            ? // we set that tile to `focused`.
                              state.clicked
                            : null
                        : state.focused,
            }

        case CLICKED_TILE_SET_SOUND_ON:
            return {
                ...state,
                sound: action.payload.tileId,
                focused: null,
            }

        default:
            return state
    }
}
