import { FunctionComponent } from 'react'
import { TILE_PAGE_PORTAL_ID } from '../constants'
import ReactDOM from 'react-dom'

const TilePagePortal: FunctionComponent = ({ children }) => {
    return ReactDOM.createPortal(
        children,
        document.getElementById(TILE_PAGE_PORTAL_ID)!
    )
}

export default TilePagePortal
