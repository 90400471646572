import { RootState } from './store'
import {
    TileId,
    FrontendTile,
    Transform,
    BoundingBox,
    MediaObjectId,
    BibliographyKey,
} from './models'
import deepEqual from 'lodash.isequal'
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect'
import { TilesState } from './store/tiles'
import { HexPoint } from './pre-build/static'
import { TILE_SIZE } from './constants'
import minBy from 'lodash.minby'
import {
    calculateBoundingBox,
    calculateDistance,
    calculateMaxHexX,
    calculateMaxHexY,
    calculateMinHexX,
    calculateMinHexY,
} from './maths'
import { getScale, getTranslate } from './panZoom/utils/math'
import { TransformMatrix } from './panZoom/types'
import { WindowDimensionState } from './store/windowDimensions'

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, deepEqual)

type IndexedTiles = { [coordsStr: string]: TileId }

const _isEven = (value: number) => Math.round(value / 2) === value / 2

const _assertInt = (value: number) => {
    if (Math.round(value) !== value) {
        throw new Error('expected an int')
    }
}

const _hexToIndex = (hex: HexPoint) => {
    _assertInt(hex.hex_x)
    _assertInt(hex.hex_y)
    return `${hex.hex_x},${hex.hex_y}`
}

export const selectScale = (state: RootState) =>
    getScale(state.panZoom.internal.transformMatrix)

export const selectTransformMatrix = (state: RootState) =>
    state.panZoom.internal.transformMatrix

export const selectTransform = (transformMatrix: TransformMatrix) => {
    return {
        scale: getScale(transformMatrix),
        translate: getTranslate(transformMatrix),
    }
}

export const selectPanZoomIsInitialized = (state: RootState) =>
    state.panZoom.isInitialized

export const selectWindowDimensions = (state: RootState) =>
    state.windowDimensions

export const memoizedSelectTransform = createDeepEqualSelector(
    selectTransformMatrix,
    selectTransform
)

export const selectMediaObjectFromId = (
    state: RootState,
    mediaObjectId: MediaObjectId
) => state.mediaObjects[mediaObjectId]

export const selectBibliographyEntry = (
    state: RootState,
    bibliographyKey: BibliographyKey
) => state.bibliography[bibliographyKey]

export const selectReferenceViewerBibliographyKeys = (state: RootState) =>
    state.referenceViewer.slice(0)

export const selectReferenceViewerBibliograhyEntries = (state: RootState) =>
    selectReferenceViewerBibliographyKeys(state)
        .map((bibliographyKey) =>
            selectBibliographyEntry(state, bibliographyKey)
        )
        .filter((bibliographyEntry) => bibliographyEntry)

export const selectReferenceViewerEntryIndex = (
    state: RootState,
    bibliographyKey: BibliographyKey
) => {
    const index = selectReferenceViewerBibliographyKeys(state).indexOf(
        bibliographyKey
    )
    return index === -1 ? null : index + 1
}

export const selectTileFromId = (state: RootState, tileId: TileId) =>
    state.tiles[tileId]

export const selectIndexedTiles = (tiles: TilesState) => {
    const indexed: IndexedTiles = {}
    Object.values(tiles).forEach((tile) => {
        if (!tile) {
            return
        }
        const tileIndex = _hexToIndex(tile.hex)
        indexed[tileIndex] = tile.id
    })
    return indexed
}

const memoizedSelectIndexedTiles = createSelector(
    (state: RootState) => state.tiles,
    selectIndexedTiles
)

export const selectZoomedTileId = (state: RootState) => state.zoomedTile.tileId

export const selectZoomedTileStatus = (state: RootState) =>
    state.zoomedTile.status

export const selectFocusedTileId = (state: RootState) =>
    state.clickedTile.focused

export const selectSoundTileId = (state: RootState) => state.clickedTile.sound

// Viewport in the coordinate system of the universe, taking into account scaling
export const selectViewport = (
    transform: Transform,
    windowDimensions: WindowDimensionState
) => {
    return {
        topLeft: {
            x: -transform.translate.x / transform.scale,
            y: -transform.translate.y / transform.scale,
        },
        bottomRight: {
            x:
                (-transform.translate.x + windowDimensions.width) /
                transform.scale,
            y:
                (-transform.translate.y + windowDimensions.height) /
                transform.scale,
        },
    }
}

export const memoizedSelectViewport = createSelector(
    memoizedSelectTransform,
    selectWindowDimensions,
    selectViewport
)

export const selectHexPointsInViewport = (
    viewport: BoundingBox,
    tileSize: number
) => {
    const intersected: Array<HexPoint> = []

    const minHexX = calculateMinHexX(viewport.topLeft.x, tileSize)
    const minHexY = calculateMinHexY(viewport.topLeft.y, tileSize)
    const maxHexX = calculateMaxHexX(viewport.bottomRight.x, tileSize)
    const maxHexY = calculateMaxHexY(viewport.bottomRight.y, tileSize)

    for (let rowInd = minHexY; rowInd <= maxHexY; rowInd++) {
        for (let colInd = minHexX; colInd <= maxHexX; colInd++) {
            // Cause funily hex have either both odd coordinates OR both even coordinates.
            if (
                (_isEven(rowInd) && _isEven(colInd)) ||
                (!_isEven(rowInd) && !_isEven(colInd))
            ) {
                intersected.push({ hex_x: colInd, hex_y: rowInd })
            }
        }
    }

    return intersected
}

export const selectTileIdsInView = (
    hexPointsIntersected: Array<HexPoint>,
    indexedTiles: IndexedTiles
) => {
    return hexPointsIntersected
        .map((hex) => indexedTiles[_hexToIndex(hex)])
        .filter((tileId): tileId is TileId => !!tileId)
}

export const isTileInView = (
    hexPointsIntersected: Array<HexPoint>,
    tile: FrontendTile
) => {
    return hexPointsIntersected.some(
        (hexInView) =>
            hexInView.hex_x === tile.hex.hex_x &&
            hexInView.hex_y === tile.hex.hex_y
    )
}

const _memoizedSelectHexPointsInViewport = createSelector(
    memoizedSelectViewport,
    (state: RootState) => TILE_SIZE,
    selectHexPointsInViewport
)

// When the viewport changes, list hex points is re-generated
// We use this hackish selector to return the same instance, of hex points if list has the same contents
const memoizedSelectHexPointsInViewport = createDeepEqualSelector(
    _memoizedSelectHexPointsInViewport,
    (hexPoints) => hexPoints
)

const _memoizedSelectTileIdsInView = createSelector(
    memoizedSelectHexPointsInViewport,
    memoizedSelectIndexedTiles,
    selectTileIdsInView
)

// Sometimes, when the viewport changes, hex points are added / removed, but the list of tiles
// in the viewport stays the same.
// We use this hackish selector to memoize the list of tile ids in that case.
const memoizedSelectTileIdsInView = createDeepEqualSelector(
    _memoizedSelectTileIdsInView,
    (tileIds) => tileIds
)

export const memoizedSelectTilesInView = createSelector(
    (state: RootState) => state.tiles,
    memoizedSelectTileIdsInView,
    (tiles, tileIds) => {
        return (
            tileIds
                .map((tileId) => {
                    const tile = tiles[tileId]
                    if (!tile) {
                        console.error(`unknown tile id ${tileId}`)
                    }
                    return tile
                })
                // Remove not found tiles. Shouldn't happen, but that's for typescript
                .filter((tile): tile is FrontendTile => !!tile)
        )
    }
)

export const memoizedSelectBackgroundHexPoints = createSelector(
    memoizedSelectHexPointsInViewport,
    memoizedSelectIndexedTiles,
    (hexPoints: Array<HexPoint>, indexedTiles: IndexedTiles) => {
        return hexPoints.filter(
            (hexPoint) => !indexedTiles[_hexToIndex(hexPoint)]
        )
    }
)

export const selectMostCentralTileInView = (
    viewport: BoundingBox,
    tilesInView: Array<FrontendTile>,
    tileSize: number
): TileId | null => {
    // This is not directly useful for the calculation but we leave it here
    if (tilesInView.length === 0) {
        return null
    }
    // subtract `tileSize / 2` because we need to offset to measure according to the center of the tile
    const viewportCenter = {
        x:
            viewport.topLeft.x +
            (viewport.bottomRight.x - viewport.topLeft.x) / 2 -
            tileSize / 2,
        y:
            viewport.topLeft.y +
            (viewport.bottomRight.y - viewport.topLeft.y) / 2 -
            tileSize / 2,
    }
    const mostCentralTile = minBy(tilesInView, (tile) =>
        calculateDistance(tile.position, viewportCenter)
    )
    return mostCentralTile!.id
}

export const selectTilesInViewBoundingBox = (
    tiles: Array<FrontendTile>,
    tileSize: number
): BoundingBox => {
    const {
        topLeft: { x: xMin, y: yMin },
        bottomRight: { x: xMax, y: yMax },
    } = calculateBoundingBox(tiles.map((tile) => tile.position))
    return {
        topLeft: { x: xMin - tileSize / 2, y: yMin - tileSize / 2 },
        bottomRight: { x: xMax + tileSize / 2, y: yMax + tileSize / 2 },
    }
}

export const memoizedSelectMostCentralTileInView = createSelector(
    memoizedSelectViewport,
    memoizedSelectTilesInView,
    () => TILE_SIZE,
    selectMostCentralTileInView
)

export const memoizedSelectTilesInViewBoundingBox = createSelector(
    memoizedSelectTilesInView,
    () => TILE_SIZE,
    selectTilesInViewBoundingBox
)

export const selectSvgTextSize = (state: RootState) => state.svgTextSize
