import React, { FunctionComponent, CSSProperties, useEffect } from 'react'
import styled from 'styled-components/macro'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import { PAGE_ZOOM_DURATION } from '../../constants'
import {
    selectTransformMatrix,
    selectZoomedTileStatus,
    memoizedSelectMostCentralTileInView,
    selectScale,
} from '../../selectors'
import { toSVG } from 'transformation-matrix'
import { ZoomStatus } from '../../store/zoomedTile'
import { useRequestZoomToTile, useDeviceScaledSettings } from '../../hooks'
import HiveTiles from './HiveTiles'
import TestMaths from '../TestMaths'

interface HiveZoomToPageContainerProps {
    animate: boolean
}

// !!! Setting width and height to 0 here is important it seems for the calculations of positioning
// of all elements to work correctly.
const HiveZoomToPageContainer = styled.div<HiveZoomToPageContainerProps>`
    width: 0px;
    height: 0px;
    ${(props) =>
        props.animate
            ? `
        transition-property: transform;
        transition-duration: ${PAGE_ZOOM_DURATION}s;
        transition-delay: 0s;
    `
            : ''}
`

const HiveZoomer: FunctionComponent = () => {
    const { zoomToPageScaleThreshold } = useDeviceScaledSettings()
    const mostCentralTileId = useSelector((state: RootState) =>
        memoizedSelectMostCentralTileInView(state)
    )
    const shouldZoom = useSelector(selectScale) > zoomToPageScaleThreshold
    const [zoomToTileEffect, zoomToTileDependencies] = useRequestZoomToTile(
        shouldZoom ? mostCentralTileId : null
    )
    useEffect(zoomToTileEffect, zoomToTileDependencies)

    const transformMatrix = useSelector(selectTransformMatrix)
    const zoomedTileStatus = useSelector(selectZoomedTileStatus)
    const inlineStyle: CSSProperties = {
        transform: toSVG(transformMatrix),
    }
    return (
        <HiveZoomToPageContainer
            style={inlineStyle}
            animate={[ZoomStatus.ZOOMING_IN, ZoomStatus.ZOOMING_OUT].includes(
                zoomedTileStatus
            )}
        >
            <TestMaths />
            <HiveTiles />
        </HiveZoomToPageContainer>
    )
}

export default React.memo(HiveZoomer)
