import React from 'react'

interface Props {
    urls: Array<string>
    videoId?: string
}

const ThumbnailVideo = ({ urls, videoId }: Props) => {
    // REF for "playsinline" :
    // https://stackoverflow.com/questions/41512200/how-do-some-websites-play-video-inline-in-ios-safari
    return (
        <video
            id={videoId}
            autoPlay
            loop
            muted
            playsInline
            height="100%"
            width="100%"
        >
            <source src={urls[0]} type="video/mp4" />
        </video>
    )
}

export default ThumbnailVideo
