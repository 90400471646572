import { FrontendTile, TileType } from '../models'
import preloadedTileData from '../pre-build/tiles.json'
import preloadedTileThumbnailData from '../pre-build/tile_thumbnails.json'
import { calculatePointFromHexPoint } from '../maths'
import { TILE_SIZE, THUMBNAILS_ROOT_URL } from '../constants'
import { CONSTANTS, MediaObjectType } from '../pre-build/static'

export const preloadedTiles = (): TilesState => {
    const tiles: TilesState = {}
    preloadedTileData.forEach((t: any) => {
        const baseTile = {
            position: calculatePointFromHexPoint(t.hex, TILE_SIZE),
            ...t,
        }

        if (t.type === 't') {
            const tileThumbnail = preloadedTileThumbnailData.filter(
                (tileThumbnail) => tileThumbnail.id === t.id
            )[0]
            if (!tileThumbnail) {
                throw new Error(`missins tile thumbnail for tile ${t.id}`)
            }

            // TODO : iOS video not supported, replace with image
            let thumbnailExtensions: Array<string> = []
            if (tileThumbnail.type === MediaObjectType.VIDEO) {
                thumbnailExtensions = CONSTANTS.THUMBNAIL_VIDEO_EXTENSIONS
            } else if (tileThumbnail.type === MediaObjectType.IMAGE) {
                thumbnailExtensions = [CONSTANTS.THUMBNAIL_IMAGE_EXTENSION]
            }

            let thumbnailUrls: Array<string> = thumbnailExtensions.map(
                (extension) => {
                    const thumbnailFileName = CONSTANTS.THUMBNAIL_FILENAME_TEMPLATE.replace(
                        'TILE_ID',
                        t.id
                    ).replace('EXTENSION', extension)
                    return `${THUMBNAILS_ROOT_URL}/${thumbnailFileName}`
                }
            )

            tiles[t.id] = {
                ...baseTile,
                type: TileType.tile,
                thumbnailType: tileThumbnail.type,
                thumbnails: thumbnailUrls,
            }
        } else {
            tiles[t.id] = {
                ...baseTile,
                type: TileType.chapter,
                thumbnails: [],
            }
        }
    })
    return tiles
}

export type TilesState = { [tileId: string]: FrontendTile | undefined }

const initialState: TilesState = {}

export function tilesReducer(
    state = initialState,
    action: { type: string }
): TilesState {
    switch (action.type) {
        default:
            return state
    }
}
