import React from 'react'
import { useSelector } from 'react-redux'
import { selectReferenceViewerBibliograhyEntries } from '../../selectors'
import styled from 'styled-components/macro'
import theme from '../../theme'
import BibliographyEntryComponent, {
    Container as BibliographyEntryComponentContainer,
} from './BibliographyEntryComponent'

const Container = styled.div`
    overflow-wrap: break-word;
    bottom: 0;
    z-index: 1;
    padding: ${theme.spacing.space1}rem;

    ${BibliographyEntryComponentContainer} {
        padding: ${theme.spacing.space1 / 2}rem 0;
    }
    ${BibliographyEntryComponentContainer}:first-child {
        padding-top: 0;
    }
    ${BibliographyEntryComponentContainer}:last-child {
        padding-bottom: 0;
    }
`

const InlineReferencePopup: React.FunctionComponent<React.HTMLAttributes<
    HTMLDivElement
>> = (props) => {
    const bibliographyEntries = useSelector(
        selectReferenceViewerBibliograhyEntries
    )
    const bibliographyElems = bibliographyEntries.map((bibliographyEntry) =>
        bibliographyEntry ? (
            <BibliographyEntryComponent
                bibliographyEntry={bibliographyEntry}
                key={bibliographyEntry.id}
            />
        ) : null
    )

    if (bibliographyEntries.length === 0) {
        return null
    }

    return (
        <Container className={props.className}>{bibliographyElems}</Container>
    )
}

export default InlineReferencePopup
