export const WINDOW_DIMENSIONS_SET = 'WINDOW_DIMENSIONS_SET'

interface RefreshDimensions {
    type: typeof WINDOW_DIMENSIONS_SET
    payload: WindowDimensionState
}

export type WindowDimensionsTypes = RefreshDimensions

const getWindowDimensions = () => ({
    width: window.innerWidth,
    height: window.innerHeight,
})

export const refreshWindowDimensions = (): WindowDimensionsTypes => {
    return {
        type: WINDOW_DIMENSIONS_SET,
        payload: getWindowDimensions(),
    }
}

export interface WindowDimensionState {
    width: number
    height: number
}

const initialState: WindowDimensionState = getWindowDimensions()

export function windowDimensionsReducer(
    state = initialState,
    action: WindowDimensionsTypes
): WindowDimensionState {
    switch (action.type) {
        case WINDOW_DIMENSIONS_SET:
            return action.payload
        default:
            return state
    }
}
