import React, { useRef } from 'react'
import { usePanZoom } from '../../panZoom/hooks'
import panZoomStyle from '../../panZoom/style'

import { useDispatch, useSelector } from 'react-redux'
import {
    selectZoomedTileId,
    memoizedSelectTilesInViewBoundingBox,
    memoizedSelectViewport,
} from '../../selectors'
import HiveZoomer from './HiveZoomer'
import { DRAG } from '../../panZoom/internal-reducer/drag'
import { WHEEL } from '../../panZoom/internal-reducer/wheel'
import { PINCH } from '../../panZoom/internal-reducer/pinch'
import {
    ActionTypes as InternalActionTypes,
    RootState as InternalPanZoomRootState,
} from '../../panZoom/internal-reducer'
import { updatePanning } from '../../panZoom/transforms'
import { zoomToViewportPoint } from '../../panZoom/transforms'
import { getScale } from '../../panZoom/utils/math'
import { RootState } from '../../store'
import { setPanZoomInternal } from '../../store/panZoom'
import { useDeviceScaledSettings } from '../../hooks'

const Hive = () => {
    const dispatch = useDispatch()
    const { maxScale, minScale } = useDeviceScaledSettings()

    const currentZoomedTileId = useSelector(selectZoomedTileId)
    const isZoomedToPage = currentZoomedTileId !== null
    const tilesBoundingBox = useSelector(memoizedSelectTilesInViewBoundingBox)
    const viewport = useSelector(memoizedSelectViewport)

    const onStateChange = (
        newState: InternalPanZoomRootState,
        previousState: InternalPanZoomRootState
    ) => {
        dispatch(setPanZoomInternal(newState, previousState))
    }

    const internalPanZoomState = useSelector(
        (state: RootState) => state.panZoom.internal
    )
    const reducer = (
        state: InternalPanZoomRootState,
        action: InternalActionTypes
    ) => {
        if (isZoomedToPage) {
            return state
        }

        switch (action.type) {
            case DRAG:
                // Compute the limits to avoid going far away from hive when dragging
                const dragLimits = {
                    topLeft: {
                        x: tilesBoundingBox.topLeft.x - viewport.topLeft.x,
                        y: tilesBoundingBox.topLeft.y - viewport.topLeft.y,
                    },
                    bottomRight: {
                        x:
                            tilesBoundingBox.bottomRight.x -
                            viewport.bottomRight.x,
                        y:
                            tilesBoundingBox.bottomRight.y -
                            viewport.bottomRight.y,
                    },
                }
                return updatePanning(
                    state,
                    action.payload.x,
                    action.payload.y,
                    dragLimits
                )

            case WHEEL:
            case PINCH:
                const newScale =
                    getScale(state.transformMatrix) * action.payload.scaleFactor
                if (newScale < minScale || maxScale < newScale) {
                    return state
                }
                return zoomToViewportPoint(
                    state,
                    action.payload.x,
                    action.payload.y,
                    action.payload.scaleFactor
                )

            default:
                return state
        }
    }

    const panZoomRef = useRef<HTMLDivElement>(null)
    const mouseAndTouchHandlers = usePanZoom(
        panZoomRef,
        reducer,
        onStateChange,
        internalPanZoomState
    )

    return (
        <div ref={panZoomRef} {...mouseAndTouchHandlers} style={panZoomStyle}>
            <HiveZoomer />
        </div>
    )
}

export default React.memo(Hive)
